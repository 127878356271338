.headerDetails {
	color: var(--lightGray);
}
.note {
	color: firebrick;
}

.table {
	width: 100%;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 1em 0 0 0;
	border-radius: 0 0 4px 4px;
}

.pageHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 1.5em 0 0 0;
}

.pageHeader h2 {
	width: 100%;
}

.pageHeader p {
	width: 100%;
}

.buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.buttons button {
	margin: 0 10px 0 0;
}

.tabs {
	list-style: none;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 0;
	padding: 3em 1.5em 0 1.5em;
	z-index: 10;
	position: relative;
}

.tabs li {
	color: var(--black);
	margin: 0 0 -1px 1em;
	padding: .3em 1em;
	border-radius: 4px 4px 0 0;
	border: 1px solid var(--black);
	border-bottom: solid 1px black;
	background: #f0f0f0;
	cursor: pointer;
	transition: color .5s;
}

.tabs li:hover {
	color: var(--accent);
}

.tabs li.active {
	font-weight: 700;
	border: solid 1px black;
	border-bottom: 1px solid white;
	background: white;
}

.tabs li.active:hover {
	color: var(--black);
}

.content {
	width: 100%;
	flex-grow: 1;
	border-top: solid 1px black;
}

.content p {
	width: 100%;
}

.restrictions {
	width: 100%;
	padding: 2em 0 1em 0;
	font-size: .75em;
	font-weight: bold;
	color: var(--darkGrey);
}

.gallery {
	width: 100%;
  	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 1em;
}

.card {
	width: 100%;
	height: auto;
  	display: flex;
  	justify-content: center;
	flex-direction: column;
	background-color: var(--white);
	margin: 1%;
	padding: 1.5em;
	border-radius: 4px;
	box-shadow: 0px 3px .5em rgba(0, 0, 0, 0.2);
	transition: all .5s;
}

.card:hover {
	
}

.cardContainer {
	width: 100%;
	display: flex;
  	justify-content: center;
  	flex-direction: column;
	/* padding: 1em; */
	text-align: center;
}

.image {
	position: relative;
	width: 100%;
	height: 200px;
	float: left;
	background-color: var(--lightGrey);
	padding: .75em;
	border: 1px solid var(--darkGrey);
	border-radius: 4px;
	overflow: hidden;
}

.image img {
	max-width: inherit;
	width: 100%;
	height: 100%;
	object-fit: scale-down;
}

.dimensions {
	width: 100%;
	max-width: 75%;
	font-size: .875em;
	font-weight: bold;
	color: var(--darkGrey);
	margin: 0 auto;
	padding: 1em 0 .3125em 0;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
}

.filename {
	width: 100%;
	max-width: 75%;
	font-size: .875em;
	font-weight: bold;
	color: var(--darkGrey);
	margin: 0 auto;
	padding: 0;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
}

.checkbox {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: -1em 0 0 0;
}

.disabledSquare {
	background-color: transparent;
	border: 0px;
}
.checkboxIcon {
	width: 20px;
	min-width: 20px;
	max-height: 20px;
	height: 20px;
	min-height: 20px;
	max-height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 3px;
	transition: all .25s;
}
.disabledSquare .checkboxIcon {
	display: block;
	color: var(--lightGrey);
	background-color: var(--lightGrey);
	border-radius: 3px;
}
.activeCheckbox {
	border: 0px;
}
.activeCheckbox .checkboxIcon {
	color: var(--white);
	background-color: var(--darkGrey);
	border-radius: 3px;
}
.activeCheckbox {
	background-color: transparent;
}

.propertyIconContainer{
	margin: 0 5px 0 10px;
    font-size: .75em;
    vertical-align: middle;
	height:100%;
	display:inline-block;
}

.propertyIconContainer:first-of-type{
	margin-left:0;
}

@media (min-width: 600px) {
	.card {
		width: 48%;
	}
}

@media (min-width: 900px) {
	.card {
		width: 31%;
	}
}

@media (min-width: 1200px) {
	.card {
		width: 23%;
	}
}

@media (min-width: 1600px) {
	.card {
		width: 18%;
	}
}

@media (min-width: 2200px) {
	.card {
		width: 14.6%;
	}
}

/* Modal */

.modalTabs {
	list-style: none;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 0;
	padding: 30px 5px 0 5px;
	border-bottom: 1px solid var(--black);
}

.modalTabs li {
	color: var(--black);
	margin: 0 0 -1px 15px;
	padding: 10px 30px;
	border-radius: 4px 4px 0 0;
	border: 1px solid var(--black);
	cursor: pointer;
	transition: color .5s;
}

.modalTabs li:hover {
	color: var(--accent);
}

.modalTabs li.active {
	background-color: white;
	border-bottom: 1px solid var(--white);
}

.modalTabs li.active:hover {
	color: var(--black);
}

.modalGallery {
	width: 100%;
	height: auto;
	max-height: 100%;
  	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 15px 15px 60px 15px;
	overflow: auto;
}

.modalGallery p {
	width: 100%;
	padding: 0 15px;
}

