.entertainment {
    height: 100vh;
    padding: 6em 1em 3em 5em;
    overflow: auto;
}

.infoBoxUploader {
    width: 100%;
    height: auto;
    order: 100;
}

.content {
    display: flex;
    flex-direction: column;
}

.title {
    padding-bottom: 2em;
}

.openModalButton {
    width: min-content;
    margin-bottom: 1em;
}

.expandCollapseContainer {
    display: flex;
    flex-direction: row;
    gap: 1em;
    padding-bottom: 2em;;
}

.category {
    width: 70vw;
    border: 1px solid gray;
    border-radius: 6px;
    padding: 1em;
    display: flex;
    gap: 1em;
}

.category + .category{
    margin-top: 1em;
}

.category svg {
    font-size: 2em;
    width: .7em;
    cursor: pointer;
}

.categoryContent {
    width: 100%;
}

.categoryHeader {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.categoryHeader img {
    width: 15rem;
    margin-right: 1em;
}

.categoryText {
    flex: 1;
}

.categoryHeader h3 {
    font-size: 1.5em;
    color: #5a5a5a;
    padding-top: .5em;;
}


.categoryButtons {
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.categoryButtons svg {
    font-size: 3em;
}

.categoryButtons .addButton {
    margin-top: .3em;
    font-size: 1.2em;
}

.categoryButtons .addButton:hover {
    border: none
}

.categoryButtons .addButton svg {
    font-size: 1.5em;
}

.categoryButtons button {
    height: min-content;
    background-color: #00BAFF;
    border: none;
    border-radius: 50px;
}

.feeds {
    margin-top: 1em;
}

.feed {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #00BAFF;
    border-radius: 6px;
    background-color: #EDF8FF;
    padding: 1em;
    transition: background-color .3s;
}

.feed:hover {
    background-color: #d5efff;
    cursor: pointer
}

.feed + .feed {
    margin-top: 1em;
}

.feed span {
    font-weight: 700;
}

.feed svg {
    font-size: 3em;
    margin-left: .3em;
}

.activeToggle {
    margin-top: .3em;
}

.feedButtonsContainer {
    align-items: center;
    display: flex;
    gap: 1em
}

/* ADD/EDIT MODAL */
.modalContainer {
    padding: 1em 1em 0em 1em;
}

.bottom {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 0 30px;
    border-top: 1px solid var(--black);
    gap: 1em;
}

.thFilter{
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #333;
}
.thFilter:active, .thFilter:focus-visible{
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
}
.thFilterContainer{
    align-self: flex-start;
    padding-bottom: 1em;
}