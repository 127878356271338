.mainContainer {
	display: flex;
	flex-grow: 1;
    overflow-y: auto;
}

.skeletonContainer {
	width: 21vw;
}
.skeletonContainerHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .2em 0;
	border-bottom: solid 1px black;
}
.skeletonScroller {
	height: 70vh;
	overflow-y: auto;
}
.dataContainer {
	width: 45vw;
	display: flex;
	flex-direction: column;
	margin: 0 2em 0 4em;
}

.componentType {
	position: relative;
	display: flex;
	align-items: center;
	padding: 10px;
	font-size: .875em;
	border-bottom: 1px solid #e8e8e8;
}

.componentType:hover {
	background-color: #EDF8FF;
	cursor: pointer;
}

.componentTypeDisabled {
	color: lightgrey;
}
.componentType svg {
	position: absolute;
	right: 1em;
	font-size: 1.5em;
	color: transparent;
}
.componentType:hover svg {
	position: absolute;
	right: 1em;
	color: var(--darkBlue);
}
.componentTypeDisabled:hover {
	background-color: #f7f7f7;
}



.headerLabel {
	display: flex;
	align-items: center;
	font-size: 1.25em;
	font-weight: 700;
	color: #545454;
}

.headerCustomLabel {
	color: #C93A6A;
    border: 2px solid #C93A6A;
    border-radius: 5px;
    font-size: 0.7em;
    margin-left: 0.4em;
	padding: 0 0.25em;
}

.headerBtnContainer {
	display: flex;
	justify-content: space-between;
}

.headerBtnContainer button {
	width: 1.75em;
	height: 1.75em;
	margin: 0 3px;
	padding: 0;
	justify-content: center;
	border-width: 0;
}

.headerBtnContainer button svg {
	margin: 0;
}
.scrollingWrapper {
	position: relative;
	overflow-y: auto;
	height: 70vh;
}
.treeEntity {
	position: relative;
	display: flex;
	justify-content: space-between;
	padding: .7em .5em;
	font-weight: 600;
	font-size: .875em;
	color: #555555;
	border: solid 2px transparent;
	border-radius: 10px;
}
.treeEntity:after {
	position: absolute;
	display: block;
	width: 97%;
	bottom: -3px;
	left: .7em;
	content: '';
	border-bottom: solid #e8e8e8 1px;
}
.treeEntity:hover {
	border-color: hsl(200deg 100% 85%);
	background: hsl(200deg 100% 98%);
	cursor: pointer;
}
.treeEntityInner {
	display: flex;
}
.selected,
.selected:hover {
	background: #FFFBF2;
	border: solid 2px #efaf00;
}
.treeEntity.selected:after,
.treeEntity:hover:after {
	border-bottom: solid transparent 1px;
}
.iconsContainer {
	margin-right: .5em;
	display: flex;
}
.angleContainer{
	display: flex;
	height: 10.5px;
	width: 1.5em;
	justify-content: center;
	transform: scale(1.4);
}
.rotatedIcon {
	transform: scale(1.4) rotate(270deg);
}
.expandCollapseAllIcon {
	display: flex;
	transform: scale(1.4);
}
.canDropComponent {
    background: lightgreen !important;
}
.systemInfoContainer {
	padding-top: 1em;
}
.settingContainer {
	margin: .75em .75em 1.5em 0;
	display: flex;
	flex-direction: column;
}
.settingContainer input {
	flex-grow: 1;
    padding: .2em .5em .2em .7em;
    font-size: .875em;
    font-weight: 500;
    color: #555555;
    line-height: 1.5em;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    outline: 0;
    transition: border .3s;
}
.settingContainer input[type="checkbox"] {
	width: auto;
}
.settingContainer input:hover, .settingContainer input:focus, .settingContainer input:active {
    border: 1px solid #26b2e7;
}

.settingContainer input:active, .settingContainer input:focus{
		background: #f4fbff;
}
.settingContainerShort {
	margin: .75em .75em 0.5em 0;
}
.settingLabel {
	margin-bottom: .1em;
	font-size: .875em;
	font-weight: 700;
	color: #555555;
	display: flex;
}
.settingHelpIcon {
	position: relative;
	margin-left: 0.4em;
	color: #0BAEEF;
	cursor: pointer;
}
.settingHelpToolTip {
	display: none;
	position: absolute;
	width: 17em;
	background: white;
	top: 0;
	left: 2em;
	font-size: .8em;
	color: var(--darkGray);
	border: solid var(--darkGray) 1px;
	padding: .6em;
	border-radius: .5em;
}
.settingHelpIcon:hover .settingHelpToolTip {
	display: block;
}
.dragHandle {
	margin-right: 1em;
	color: #e2e2e2;
	cursor: grab;
}
.treeEntity:hover .dragHandle {
	color: hsl(200deg 100% 85%);
}
.treeEntity.selected .dragHandle {
	color: hsl(34deg 72% 80%);
}
:global(.jsoneditor) {
	border-radius: .4em;
	overflow: hidden;
}

.container {
	display: inline-block;
	position: relative;
	margin-bottom: .9em;
	cursor: pointer;
	font-size: 1.3em;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

  /* Hide the browser's default checkbox */
.container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
  /* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 1.1em;
	width: 1.1em;
	background-color: #eee;
	border-radius: .15em;
	transition: all .2s;
}

.boxStyleCheckmark{
	left: 0.3em;
}

  /* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
	background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
	background-color: #2196F3;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
	content: "";
	position: absolute;
	display: none;
  }
  
  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
	display: block;
  }
  
  /* Style the checkmark/indicator */
  .container .checkmark:after {
	left: .35em;
	top: .13em;
	width: .22em;
	height: .55em;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
  }

/* Listing Group Settings */

.listingGroupDataContainer {
	padding-bottom: 1em;
	border-bottom: 1px solid #545454;
}

.listingGroupSettingSectionHeader {
	background-color: #eee;
	margin: 0.5em 0; 
}

.listingGroupSelectTierContainer {
	display: flex;
	flex-direction: row;
    align-items: center;
	flex-wrap: wrap;
}

.listingGroupTierLabel {
	font-size: 1.2em;
}

.listingGroupLabel {
	font-size: 1.8em;
	margin-right: 0.5em;
}

.listingGroupSelectTierContainer button {
	margin-right: 0.5em;
}

.listingColumnGroupContainer {
	display: flex;
	margin: 1em 0.5em 1em 0;
	overflow-x: unset;
}

.listingColumnGroup {
	border: 1px solid #ccc;
	border-radius: 5px;
	width: 32%;
	margin-right: 0.5em;
}

.listingColumnGroup div {
	padding: 0 0.5em;
}

.listingColumnGroupDelete {
	width: 20px;
}
.listingColumnGroupDelete:hover {
	cursor: pointer;
}

/* Custom Switch Setting */

.customSwitch {
    width: 100%;
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.customSwitch div {
    margin: 0 5px 0 5px;
    flex-grow: 1;
    flex-basis: 0;
    width: 176px;
}

.customSwitch div:first-child {
    margin: 0
}

.customSwitch div:last-child {
    margin: 0 0 0 -2px;
}

.customSwitch label {
    z-index: 1;
    height: 42.5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    cursor: pointer;
    transition: background-color ease 0.3s;
}

.customSwitch label span {
    width: 125px;
    overflow: hidden;
    white-space: nowrap;
    display: block;
    text-overflow: ellipsis;
    font-size: 1em;
    line-height: 1.5em;
    color: #808080;
    text-align: center;
}

.customSwitch input {
    z-index: -1;
    position: absolute;
    opacity: 0;
}

.customSwitch input[type=radio]+label {
    background-color: #fff;
    border: 1px solid #808080;
}

.customSwitch input[type=radio]:hover+label {
    border: 1px solid #26b2e7;
}

.customSwitch input[type=radio]:focus+label {
    border: 1px solid #26b2e7;
    outline: 3px solid #26b2e7;
}

.customSwitch input[type=radio]:focus+label span {
    color: #fff;
}

.customSwitch input[type=radio]:checked+label {
    position: relative;
    background: #015488;
    color: #fff;
}

.customSwitch input[type=radio]:checked+label span {
    color: #fff;
}

.uploader {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 0;
}

.uploaderButtons {
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 100%;
	justify-content: flex-end;
}

.uploaderButtons button {
	margin: 0 0 0 10px;
	padding-top: .725em;
	padding-bottom: .725em;
}

.uploaderButtons button svg{
	margin:0;
}

.uploaderDropdown {
	flex: 1;
	z-index: 5;
}

.mobileDisplay{
	display: flex;
    flex-direction: row;
    align-items: center;
}

.mobileDisplay{
    display: flex;
    width: auto;
    min-width: 34px;
    font-family: unset;
    font-weight: 700;
    color: var(--white);
    align-items: center;
    border-radius: 4px;
    box-shadow: none;
    transition: all .3s;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    background-color: #AAA;
    border: none;
    font-size: 1em;
    text-align: center;
    padding: 0.25em;
    height: fit-content;
}

.mobileDisplay.active{
	background-color: #4BA046;
}

.mobileDisplay button:hover{
	border:none;
}

.mobileDisplay svg{
	margin:0;
	width: 0.875em;
	margin: 0 auto;
	height: 20px;
	margin-top: 1px;
}

.buttonsDropdown{
	flex-grow: 1;
	min-width: 150px;
}

.buttonsDropdownFixed{
	flex-grow: 1;
	max-width: 200px;
	width: 200px;
}
.boxRow {
	display: flex;
	width: 100%;
}
.listingGroupSettings {
	min-width: 30%;
}
.autoWidthSelect{
	max-width: none;
}

.cancelButton{
	background-color:white;
	color: var(--darkBlue);
}

.cancelButton:hover{
	background-color:white;
}

.confirmModalButtonContainer{
	display: flex;
	flex-direction: row;
}
.listingWarningModalContainer{
	display: flex;
	flex-direction: column;
}
.listingWarningModalContainer p{
	margin: 10px 5px;
}
.warningModalButton{
	display: flex;
    justify-content: flex-end;
	margin: 10px;
}
button.arrowButton svg{
	margin: 0;
}

.settingContainer :global(.invalidValue) {
	border: 1px solid firebrick !important;
	position: relative;
}

.inputErrorMessage {
	color: firebrick;
	margin-top: 5px;
	font-size: 11px;
}