.entertainmentContent {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-left: 10px
}

.feedContainer {
    position: relative;
    width: 22%;
    padding: 1rem 2.5rem 3rem 2.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.titleLine {
    width: 100%;
    font-size: 1.6rem;
    font-weight: 700;
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.feedContainer img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 0.1s ease-in-out;
}

.feedContainer img:hover {
    transform: scale(1.05);
}

.feedContainer input {
    position: absolute;
    bottom: 1rem;
}

.description {
    width: 100%;
}

.selectAll {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    gap: 0.5rem;
    font-weight: bold;
}

.imageModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}

.imageModal .imageContainer {
    position: relative;
    width: 80%;
    height: 80%;
}

.imageModal img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.imageModal .closeButton {
    position: absolute;
    top: 3rem;
    right: 3rem;
    height: 4rem;
    width: 4rem;
    font-size: 3rem;
    color: #0f80a9;
    cursor: pointer;
    background: transparent;
    border: 3px solid #0f80a9;
    border-radius: 2rem;
}