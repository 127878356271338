.pageHeader {
    position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 1.5em 0 0 0;
}

.pageHeader h2 {
	width: 100%;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.buttons button {
    margin: 0 0 0 10px;
}

.sectionLabel {
	width: 100%;
	margin-bottom: .3125em;
	color: var(--darkBlue);
	font-weight: 700;
	font-size: 1.2em;
	text-transform: uppercase;
}

.settingsForm {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 50px 0 4em 0;
}

.column1 {
    width: 100%;
}

.column2 {
    width: 100%;
}

.settingsField {
    width: 100%;
    flex-direction: row;
    flex-direction: wrap;
    padding: 0 0 15px 0;
}

.label {
    font-size: .875em;
	font-weight: 600;
	color: var(--black);
    padding: .25em 0;
}

.settingsField a {
    width: 100%;
    font-size: .875em;
	font-weight: 600;
	color: var(--black);
    padding: .25em 0;
}

.settingsField input {
    box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	font-weight: 400;
	line-height: 1.5em;
	color: black;
	padding: .25em 1em .25em 1em;
	border-radius: 4px;
	border: 1px solid #d0d0d0;
	outline: 0;
	transition: border .3s;
}

.checkbox .disabledSquare {
	background-color: transparent;
	border: 0px;
}

.checkbox .disabledSquare .checkboxIcon {
	display: block;
	color: var(--lightGrey);
	background-color: var(--lightGrey);
	border-radius: 3px;
}
.checkbox .activeCheckbox {
	border: 0px;
}
.checkbox .activeCheckbox .checkboxIcon {
	color: var(--white);
	background-color: var(--accent);
	border-radius: 3px;
}

.checkbox .activeCheckbox{
	background-color: transparent;
}

.checkboxIcon{
    display: flex;
    height: 14px;
    width: 14px;
    font-size: .875em;
    font-weight: 600;
    justify-content: center;
    align-items: center;
}

.checkbox {
	height: 100%;
    width: 100%;
    display: flex;
}

@media (min-width: 768px) {
    .settingsForm {
        max-width: 75%;
    }

    .column1 {
        width: 49%;
    }
    
    .column2 {
        width: 49%;
    }
}

@media (min-width: 1280px) {
    .settingsForm {
        max-width: 50%;
    }
}