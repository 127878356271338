.default {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 10px solid red;
}

/* Split screen landing page */
.splitScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: 
    /* top, transparent red, faked with gradient */ 
    linear-gradient(
        rgba(1, 84, 136, 0.8),
        rgba(1, 84, 136, 0.95)
    ),
    /* bottom, image */
    url('https://ts-public-images.s3.amazonaws.com/mountains.jpg');
    background-size: cover;
    background-position: center;
}

.halfScreen {
    width: 100%;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.halfScreen img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 0 0 30px 0;
    display: flex;
}


.halfScreen p {
    max-width: 350px;
    font-size: 1em;
    text-align: center;
    margin: 0 auto;
    padding: 0 0 30px 0;
    text-align: center;
}

.halfScreen iframe {
    flex-grow: 1;
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
}

.loginHalf{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
}

.loginHalf:first-of-type{
    background: #015488;
    color: white;
}
.loginHalf:first-of-type{
    background: 
    linear-gradient(
        rgba(1, 84, 136, 0.8),
        rgba(1, 84, 136, 0.95)
    ),
    url('https://ts-public-images.s3.amazonaws.com/mountains.jpg');
    background-size: cover;
    background-position: center;
}

.loginHalf:nth-of-type(2){
    background: #BECEDC;
}

.loginContainer{
    margin: auto;
    width: 100%;
    padding: 15px;
    background-size: cover;
    background-position: center;
}

.loginContainer:first-of-type{
}

.loginLink, .loginLink:visited{
    color: #008bbf;
}
.legacyLogin {
    color: var(--darkBlack);
    background-color: rgba(255, 255, 255, 0.85);
}

.legacyLogin i {
    color: var(--darkBlue);
    text-transform: uppercase;
}

.legacyLogin .splitScreenButton {
    width: fit-content;
    margin: auto;
    font-size: 1.25em;
    text-decoration: none;
    color: var(--white);
    background-color: var(--darkBlue);
    border: 2px solid var(--darkBlue);
}

.legacyLogin .splitScreenButton:hover {
    background-color: var(--darkBlueHover);
    border: 2px solid var(--darkBlueHover);
}

.sparkLogin .splitScreenButton{
    background-color: var(--white);
    border: 2px solid var(--white);
    color: var(--darkBlue);
}

.sparkLogin .splitScreenButton:hover{
    border: 2px solid var(--darkBlueHover);
    color: var(--white);
}

.newLogin {
    color: var(--white);
    background-color: var(--white);
    padding-top: 4em;
}

.newLogin i {
    color: var(--accent);
    text-transform: uppercase;
}

.newLogin .splitScreenButton {
    width: fit-content;
    font-size: 1.25em;
    text-decoration: none;
    color: var(--darkBlue);
    background-color: var(--white);
    border: 2px solid var(--white);
}

.newLogin .splitScreenButton:hover {
    background-color: var(--accentLight);
    border: 2px solid var(--accentLight);
}

@media (min-width: 768px) {
    .splitScreen {
        flex-direction: row;
    }

    .halfScreen {
        height: 100vh;
    }

    .legacyLogin {
        width: 40%;
        margin-top: 85px;
    }

    .newLogin {
        width: 60%;
    }
}

@media (min-width: 992px) {
    .halfScreen img {
        max-width: 400px;
    }

    .halfScreen p {
        max-width: 450px
    }
    
}

/* 5.0 Login */
.login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: 
    /* top, transparent red, faked with gradient */ 
    linear-gradient(
        rgba(1, 84, 136, 0.8),
        rgba(1, 84, 136, 0.95)
    ),
    /* bottom, image */
    url('https://ts-public-images.s3.amazonaws.com/mountains.jpg');
    background-size: cover;
    background-position: center;
}

.forgotPassword {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    padding: 0 30px;
}

.resetPassword, .register{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    padding: 0 30px;
}

.welcomeImage {
    width: 100%;
    max-width: 350px;
    height: auto;
    margin: 0 auto;
    text-align: center;
}

.welcomeImage img {
    width: 100%;
    margin: -1em 0 0 0;
    height: auto;
}

.form button.buttonDisabled {
    color: var(--white);
    background-color: var(--lightGrey);
    border: 2px solid var(--lightGrey);
    transition: background .3s;
    cursor: not-allowed;
}

.emailSent {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--black);
    padding: 0 30px;
}

.forgotPassword .formContainer, .resetPassword .formContainer, .register .formContainer, .emailSent .formContainer {
    width: 100%;
}

.forgotPassword .marketingContainer, .resetPassword .marketingContainer, .register .marketingContainer, .emailSent .marketingContainer {
    display: none;
}

.header {
	position: fixed;
	top: 0;
	z-index: 200;
	width: 100%;
	/* height: auto; */
	height: 4em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	/* padding: 12px 15px 12px 23px; */
	padding: .8em 1em .8em 1.5em;
	background-color: var(--darkBlack);
	box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 0.4);
}

.left {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	color: var(--white);
}

.logo {
	width: 250px;
	height: auto;
	padding: 0 15px 0 0;
}

.logo img {
	/* width: 155px; */
	width: 10em;
}

.formContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--white);
    padding: 150px 0 100px 0;
}

.form {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form h1 {
    color: var(--black);
    font-weight: bold;
    text-align: center;
}

.form p {
    width: 100%;
    font-size: 1.25em;
    text-align: center;
}

.email, .password {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 0 0 7.5px;
}

.email svg, .password svg {
    z-index: 1;
    color: var(--darkGrey);
    margin: 0 -30px 0 0;
}

.form form {
    width: 100%;
}

.form input {
    z-index: 0;
    width: 100%;
    box-sizing: border-box;
    font-size: 1.25em;
    line-height: 1.5em;
    color: var(--black);
    margin: 10px 0;
    padding: 5px 5px 5px 35px;
    border-radius: 4px;
    border: 1px solid var(--darkGrey);
    outline: 0;
    transition: border .5s;
}

.form input::placeholder {
    color: var(--lightGrey);
}

.form input:hover {
    border: 1px solid var(--accent);
}

.form input:focus {
    border: 1px solid var(--accent);
}

.error {
    font-size: 1em;
    color: firebrick;
    text-align: center;
    padding: 10px 0;
}

.valid {
    font-size: 1em;
    color: forestgreen;
    text-align: center;
    padding: 10px 0;
}

.warning {
    font-size: 1em;
    color: #FF8C00;
    text-align: center;
    padding: 10px 0;
}

.form button {
    width: 100%;
    font-size: 1.25em;
	font-weight: bold;
	text-transform: uppercase;
	background-color: var(--accent);
    color: var(--white);
    margin: 10px 0;
	padding: 12.5px;
	border: 0;
	border-radius: 4px;
	box-shadow: none;
    transition: background .5s;
    cursor: pointer;
}

.form button:hover {
    background-color: var(--accentDark);
}

.form .loader {
    position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.marketingContainer {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.marketingContent {
    width: 100%;
    max-width: 400px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.marketingContent img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 0 0 30px 0;
}

.marketingContent h2 {
    font-size: 2.5em;
    font-weight: bold;
    color: var(--white);
    margin: 0 0 .5em 0;
}

.marketingContent p {
    font-size: 1.25em;
    line-height: 1.4;
    color: var(--white);
    margin: 0 0 2em 0;
}

.links {
    width: 100%;
    max-width: 75%;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    font-size: 1em;
    text-align: center;
    padding: 15px 0;
}

.links a {
    color: var(--darkBlue);
    font-weight: bold;
    text-decoration: underline;
}

.link {
    width: 100%;
    color: var(--darkBlue);
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    padding: 15px 0;
    cursor: pointer;
}

.link:hover {
    text-decoration: underline;
}

@media (min-width: 992px) {
    .login {
        flex-direction: row;
    }

    .formContainer {
        width: 40%;
        height: 100vh;
    }

    .form {
        max-width: 350px;
    }

    .marketingContainer {
        width: 60%;
        height: 100vh;
    }

    .marketingContent {
        max-width: 500px;
    }

    .marketingContent img {
        max-width: 400px;
    }

}

@media (min-width: 1280px) {
    .form {
        max-width: 400px;
    }

    .marketingContent {
        max-width: 700px;
    }

}

@media (max-width: 767px) {
    .formContainer, .halfScreen {
        height: 100vh;
        height: 100dvh;
    }

    .splitScreen {
        height: 100vh;
        height: 100dvh;
    }

    .marketingContainer, .newLogin {
        display: none;
    }
}