/* Generic classes */
.halfcolumn {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
}

.fullcolumn {
    display: flex;
    flex-basis: 100%;
    flex-direction: column;
    padding: 0 15px;
}

.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
}

.textcontainer {
    background-color: #faa800;
    padding: 5px;
    text-align: center;
    position: relative;
}

.background {
    padding: 10px;
}

.fieldcontainer {
    display: flex;
    flex-direction: row;
}

.smallinput:first-of-type {
    margin-right: 20px;
}

.buttonContainer{
    text-align: center;
    margin-top: 15px;
}

textarea {
    box-sizing: border-box;
    width: 100%;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5em;
    color: black;
    padding: 0.25em 1em 0.25em 1em;
    border-radius: 4px;
    border: 1px solid #d0d0d0;
    outline: 0;
    transition: border 0.3s;
    font-family: "Montserrat", sans-serif;
}

.activatebutton {
    text-align: center;
    display: inline-block;
    padding: 0.25em 2em;
    margin: auto;
}

.messageContainer p {
    font-size: 1.2em;
}

.messageContainer .messageBanner {
    padding: 10px;
    background-color: #ff0000;
    font-size: 3em;
    font-weight: 800;
    text-transform: uppercase;
    color: #ffffff;
}

.arrowsContainer>div {
    position: absolute;
    top: 50%;
    color: white;
    font-size: 2em;
    font-weight: 800;
    padding: 5px;
    cursor: pointer;
}

.arrowsLeft {
    left: -10px;
}

.arrowsRight {
    right: -10px;
}

/* Types */
.warning .background {
    background: repeating-linear-gradient(-45deg,
            #ffd700,
            #ffd700 20px,
            #000 20px,
            #000 40px);
}

.warning .icon i {
    color: #ffd700;
}

.urgent .background {
    background: repeating-linear-gradient(-45deg,
            #ff8c00,
            #ff8c00 20px,
            #000 20px,
            #000 40px);
}

.urgent .icon i {
    color: #ff8c00;
}

.emergency .background {
    background: repeating-linear-gradient(-45deg,
            #b22222,
            #b22222 20px,
            #000 20px,
            #000 40px);
}

.emergency .icon i {
    color: #b22222;
}

.sidebar .icon i {
    color: #fff;
}

@-webkit-keyframes background {
    to {
        transform: translateX(-56px);
    }
}

@keyframes background {
    to {
        transform: translateX(-56px);
    }
}

.simple .animate {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    opacity: 0;
    overflow: hidden;
}

@keyframes simpleFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.simple .background {
    z-index: 999;
    position: absolute;
    width: calc(100vw + 100px);
    top: 0;
    left: 0;
    height: 100vh;
}

.simple .content {
    z-index: 1000;
    position: absolute;
    width: 100%;
    height: 100vh;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transform: translate(-50%, -50%);
    background-color: #fff;
}

.simple .icon {
    width: 100%;
    color: #000;
    margin-top: -5%;
    text-align: center;
}

.simple .icon i {
    font-size: 8vw;
}

.simple .text {
    width: 90vw;
    padding: 30px 0 0 0;
    text-align: center;
}

.simple .text h1 {
    font-size: 5vw;
    color: #000;
}

.simple .text p {
    font-size: 2vw;
    color: #000;
}

/* Sidebar format */

.sidebar .animate {
    position: absolute;
    width: 20%;
    height: 100%;
    top: 0;
    animation: sidebarSlideIn 1s forwards;
    opacity: 0;
    overflow: hidden;
}

@keyframes sidebarSlideIn {
    0% {
        opacity: 0;
        left: -100vw;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

.sidebar .background {
    z-index: 999;
    position: absolute;
    width: calc(100vw + 100px);
    top: 0;
    left: 0;
    height: 100vh;
    animation: background 1s linear infinite;
}

.sidebar .content {
    z-index: 1000;
    position: absolute;
    width: 80%;
    height: 100vh;
    top: 50%;
    left: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transform: translate(-50%, -50%);
    background-color: #fff;
    opacity: 0;
    animation: sidebarFadeIn 1s forwards;
    animation-delay: 1s;
}

.sidebar .icon {
    position: absolute;
    width: 100%;
    top: 15%;
    left: -18%;
    color: #fff;
    animation: sidebarFadeIn 1s forwards;
    opacity: 0;
    animation-delay: 2s;
}

@keyframes sidebarFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.sidebar .icon i {
    font-size: 8vw;
    animation: sidebarFadeInOut 5s forwards;
    animation-delay: 2s;
    opacity: 0;
}

@keyframes sidebarFadeInOut {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }

    20% {
        opacity: 1;
        transform: scale(1);
    }

    30% {
        transform: scale(0.7);
    }

    40% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.7);
    }

    60% {
        transform: scale(1);
    }

    70% {
        transform: scale(0.7);
    }

    80% {
        transform: scale(1);
    }

    90% {
        opacity: 1;
        transform: scale(0.7);
    }

    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.sidebar .text {
    position: absolute;
    max-width: 90%;
    top: 15%;
    opacity: 0;
    text-align: left;
    animation: sidebarFadeIn 2s forwards;
    animation-delay: 5s;
}

.sidebar .text h1 {
    font-size: 5vw;
    color: #000;
}

.sidebar .text p {
    font-size: 2vw;
    color: #000;
}

/* Border format */

.border .animate {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    animation: borderSlideIn 1s forwards;
    opacity: 0;
    overflow: hidden;
}

.border .background {
    z-index: 999;
    position: absolute;
    width: calc(100vw + 100px);
    top: 0;
    left: 0;
    height: 100vh;
    animation: background 1s linear infinite;
}

@keyframes borderSlideIn {
    0% {
        opacity: 0;
        left: -100vw;
    }

    100% {
        opacity: 1;
        left: 0;
    }
}

.border .content {
    z-index: 1000;
    position: absolute;
    width: 95vw;
    height: 90vh;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    transform: translate(-50%, -50%);
    background-color: #fff;
    opacity: 0;
    animation: borderFadeIn 1s forwards;
    animation-delay: 1s;
}

.border .icon {
    color: #ff0000;
    animation: borderFadeIn 1s forwards;
    opacity: 0;
    animation-delay: 2s;
}

@keyframes borderFadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.border .icon i {
    font-size: 10vw;
    animation: borderFadeInOut 3s forwards;
    animation-delay: 2s;
    opacity: 0;
}

@keyframes borderFadeInOut {
    0% {
        opacity: 0;
        transform: scale(0.7);
    }

    20% {
        opacity: 1;
        transform: scale(1);
    }

    30% {
        transform: scale(0.7);
    }

    40% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.7);
    }

    60% {
        transform: scale(1);
    }

    70% {
        transform: scale(0.7);
    }

    80% {
        transform: scale(1);
    }

    90% {
        opacity: 1;
        font-size: 10vw;
        transform: scale(0.7);
    }

    100% {
        opacity: 1;
        font-size: 3vw;
        transform: scale(1);
    }
}

.border .text {
    width: 95%;
    opacity: 0;
    text-align: center;
    animation: borderFadeIn 2s forwards;
    animation-delay: 5s;
}

.border .text h1 {
    font-size: 5vw;
    color: #000;
    margin: 30px 0 0 0;
}

.border .text p {
    font-size: 2vw;
    color: #000;
}