.mainContainer {
	display: flex;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
    width: calc(100% + 1px);
	margin-left: -1px;
    height: 2.5em;
	padding: 0 .5em 0 1em;
    border-bottom: 1px solid darkgrey;
}

.headerLabel {
	font-size: 1em;
	font-weight: 700;
}

.headerBtnContainer {
	display: flex;
	justify-content: space-between;
}

.headerBtnContainer button {
	width: 1.75em;
	height: 1.75em;
	margin: 0 3px;
	padding: 0;
	justify-content: center;
	border-width: 0;
}

.headerBtnContainer button svg {
	margin: 0;
}

.templateContainer {
	width: 25%;
    margin-right: 30px;
}

.template {
	border: 1px solid darkgrey;
	border-radius: 5px;
	cursor: pointer;
    margin: 1em 0px;
	padding: 1em .7em; 
}

.template div {
    padding: 0px 10px;
}

.templateActive {
	background-color: #EDF8FF;
	border: 1px solid #67C3FF;
}

.templateName {
    font-weight: bold;
	margin-bottom: 10px;
}

.templateData {
	display: flex;
}

.templateDataKey {
	width: 40%;
}

.dataContainerSmall {
    width: 25%;
}

.dataContainerLarge {
    width: 75%;
}

.stepOneData {
    padding: 0 .5em 0 1em;
}

.label {
    margin: 5px 0;
}

.input {
    width: 100%;
    height: 30px;
    border: 1px solid darkgrey;
    border-radius: 5px;
    text-decoration: none;
    font-size: 15px;
    padding-left: 10px;
}

.input:focus, .input:active, .input:hover {
    outline: none;
	border: 1px solid #26b2e7;
}

.input:focus, .input:active{
	background: #f4fbff
}

.systemContainer {
	display: flex;
	justify-content: space-around;
	align-items: center;
	height: 60px;
	width: 90%;
	border-radius: 10px;
	margin: 0.5em 0;
	cursor: pointer;
}

.systemEmpty {
	background-color: #F7F7F7;
	border: 1px solid #C3C3C3;
}

.systemEnabled {
	background-color: #EDF8FF;
	border: 1px solid #67C3FF;
}

.systemDisabled {
	background-color: #FFF7FA;
	border: 1px solid #D990A8;
}

.systemPendingChange {
	border: 3px solid gold;
}

.systemIcon {
	width: 50px;
}

.resolutionContainer {
	display: flex;
}

.resolutionIcon {
	width: 30px;
}

/* Notes */
.notesContainer {
	display: flex;
	width: 100%;
	flex-direction: column;
	margin-left: 30px;
}

.notesTitle {
	display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% + 1px);
    margin: 0 0 0 30px;
    height: 2.5em;
    padding: 0 0.5em 0 1em;
    border-bottom: 1px solid darkgrey;
	font-weight: 700;
}
.notesSection {
	overflow: scroll;
}

.noteContainer {
	display: flex;
	justify-content: space-between;
	padding: 1em;
	border-bottom: 1px solid #E2E2E2
}

.noteResolution {
	margin: 0;
    font-size: 1em;
    font-weight: 500;
	margin-bottom: 10px;
}
.noteResolutionHeader{
	font-weight: 700;
}
.noteImage {
	width: 100px;
	padding-left: 1em;
	cursor: pointer;
}

/* Image Modal */
.modalContainer {
	max-height: 100%;
	max-width: 100%;
	overflow: scroll;
}

.modalImage {
	width: 100%;
	height: 100%;
}

.modalBottomBar {
	position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 0 30px;
    border-top: 1px solid
}

.iconContainer{
	width: 100%;
	height: 100%;
}
.iconContainer svg{
	height: 50px !important;
    width: 50px !important;
}

.imageContainer{
	min-width: 100px;
    text-align: center;
}