.sidebar {
	position: absolute;
	z-index: 99;
	top: 0;
	left: 0;
	height: 100vh;
	width: 14em;
	background: #fff;
	color: #323232;
	border-right: solid 1px gray;
	transition: width .2s ease-in-out .1s;
	overflow: hidden;
	cursor: pointer;
	transform: translateZ(0);
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
	will-change: width;
}
.collapsed {
	width: calc(3em + 1px);
}
.menu {
	margin: 0;
	padding: 4em 0 0 0;
	list-style: none;
}

.menu li {
	width: 100%;
	height: 3em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	color: #323232;
	cursor: pointer;
	transition: background .3s;
}
.menu li:hover {
	background: #d8f4ff;
}

.menu li.active {
	font-weight: bold;
	color: #fff;
	background: var(--accent);
}

.icon {
	z-index: 1;
	position: fixed;
    width: 3em;
    height: 3em;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
	background: #fff;
	transition: background .3s;
}
.menu li:hover .icon {
	background: #d8f4ff;
}

.menu li.active .icon {
	background: var(--accent);
}

.menu li svg {
}

.name {
	opacity: 1;
	margin: 0 0 0 3em;
	white-space: nowrap;
}


.expanded {
	/* background: blue;
	text: orange; */
}
.toggle {
	position: absolute;
	bottom: 150px;
	/* left: 210px; */
	left: 12.9em;
}

.toggle button {
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
    font-size: 16px;
    background: var(--accent);
	color: #fff;
	padding: 0 2.5px 0 0;
	border: 0;
    border-radius: 50%;
    box-shadow: none;
	/* transition: all .3s; */
	cursor: pointer;
	outline: 0;
}

.toggle button:hover {
    background: #0f80a9;
}

.disabled {
	background: var(--lightGrey);
	cursor: not-allowed;
}

.disabled .icon {
	opacity: .5;
	background: var(--lightGrey);
	color: var(--darkGrey);
}

.disabled li {
	pointer-events: none;
}

.disabled .name {
	opacity: .5;
	color: var(--darkGrey);
}
@media (max-width: 1700px) {
	.sidebar {
		font-size: 15px;
	}
}
@media (max-width: 1500px) {
	.sidebar {
		font-size: 14px;
	}
}
@media (max-width: 1300px) {
	.sidebar {
		font-size: 13px;
	}
}
@media (max-width: 1100px) {
	.sidebar {
		font-size: 12px;
	}
}