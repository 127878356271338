.container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	width: auto;
	height: 80%;
	margin-top: 2em;
	/* border: 1px solid #e4e4e4; */
}
.scrollContainer {
	flex-grow: 1;
	overflow-y: auto;
    height: auto;
}
.scrollContainer::-webkit-scrollbar {
	height: 0px;	/* height of horizontal scrollbar  */
	width: 0px;	/* width of vertical scrollbar */
}
.title {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5em 0 0 0;
}
.column {
	display: flex;
    flex-direction: column;
	width: 35%;
    border-left: solid 1px #E2E2E2;
    border-bottom: solid 1px #E2E2E2;
}
.column:last-child {
	border-right: solid 1px #E2E2E2;
}

.shortColumn{
	width: 15%;
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
    width: calc(100% + 1px);
	margin-left: -1px;
	color: #fff;
	min-height: 40px;
    height: 2.5em;
	padding: 0 .5em 0 1em;
	border-right: 1px solid white;
	background: #222;
}
.headerLabel {
	font-size: 1em;
	font-weight: 700;
}
.headerBtnContainer {
	display: flex;
}

.headerBtnContainer button {
	width: 1.75em;
	height: 1.75em;
	margin: 0 3px;
	padding: 0;
	justify-content: center;
	border-width: 0;
	background: var(--accent);
	background: #0BAEEF;
}
.headerBtnContainer button svg {
	margin: 0;
}

.themeColumn div {
	border-right: none;
}


.row {
    position: relative;
	display: flex;
	align-items: center;
	width: calc(100% + 2px);
	height: 3.125em;
    margin-left: -1px;
	padding: 0 0 0 1em;
	font-size: 1em;
	border-top: 1px solid transparent;
	border-bottom: 1px solid #E2E2E2;
    z-index: 10;
	cursor: pointer;
}



.row:hover {
	background: hsl(0deg 0% 97%);
}
.rowActive, .rowActive:hover {
	background: #d9f4ff;
}




.themeRow {
	display: flex;
	align-items: center;
	width: 100%;
}
.themeName {
	width: 38%;
}
.icon {
	display: flex;
    align-items: center;
	width: 40px;
	height: 40px;
}

.activeIcon {
	fill: red; /* Custom SVGs */
	color: red; /* Font Awesome Icons */
}

.modalButtonContainer {
	display: flex;
	justify-content: flex-end;
	width: 100%;
	padding: 1em 2em;
}
.modalContent {
	padding: 1.5em 2em;
}

.notesContainer {
	display: flex;
	flex-direction: column;
	padding: 5px;
	margin-left: 10px;
	background-color: #FFF;
	
}
.notesContainer .notesSub{
	display: flex;
}

.notesUploader{
	width: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    flex-wrap: wrap;
    margin: 0;
    padding: 1.5em 1em;
}

.notesUploader img{
	width: 50%;
	height: auto;
}
.notesHeader {
	margin-bottom: 10px;
	font-weight: 700;
}
.uploadButtonContainer{
	position: absolute;
    right: -25%;
    bottom: 0;
}

.uploadButton{
	padding-left: 0.5em;
    padding-right: 0;
}


.uploader-buttons{
	justify-content: center;
}

.iconContainer svg{
	height: 50px !important;
    width: 50px !important;
}
/* SVGs */
:global(.monitorShape) {
	fill: #eaeaea;
	stroke-width: 15px;
    stroke: #00000026;
}
.activeIcon :global(.monitorShape) {
    fill: #09aeef;
    stroke: hsl(197deg 93% 39%);
}
:global(.svgText) {
    fill: gray;
}
.activeIcon :global(.svgText) {
    fill: white;
}
:global(.customCheckCircle) {
	color: rgba(0, 0, 0, 0.12);
}
.activeIcon :global(.customCheckCircle) {
	color: #09aeef;
}
:global(.is_custom) {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-grow: 1;
    padding-right: 2em;
}
:global(.is_custom).icon svg {
    width: 1.5em;
    height: 1.5em;
}