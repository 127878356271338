:root {
	--heights: 14em;
	--darkGray2: #707070;
	--darkGray3: #555555;
	--darkGray: #434343;
	--lightGray: #808080;
}


.pl2 {
	padding-left: 2em;
}
.mt3 {
	margin-top: 3em;
}
.componentSelector {
    width: 320px;
    width: 20em;
    height: calc(100vh - 64px);
	padding: 1.5em 1.5em 1.5em 1.5em;
	color: var(--darkGray);
    background: #F0F2F5;
}
.mainContent {
	/* display: flex;
	flex-direction: column; */
	flex-grow: 1;
	height: calc(100vh - 4em);
	padding: 0 1.5em 1.5em 1.5em;
	color: var(--darkGray);
	overflow: auto;
}
.h1 {
	font-size: 2em;
	color: #0B0B0B;
}
.description {
	font-size: .8em;
	color: var(--lightGray);
}
.component {
	font-size: 1.1em;
}
.componentName {
	/* background: gray; */
	padding: .5em .8em;
	cursor: pointer;
	transition: all .3s;
}
.componentName:hover {
	color: white;
    background: #525252;
}
.componentNameActive {
	padding: .5em .8em;
	cursor: pointer;
	transition: all .3s;
	color: white;
    background: #525252;
}
.componentLine {
	/* margin: .3em 0; */
	border-bottom: solid 1px var(--darkGray);
	/* border-color: var(--darkGray); */
}

.systemGroupsTable {
	display: flex;
	/* margin-top: 10em; */
}



/* ============== System Groups LEFT ============== */
.systemGroupsLeft {
	/* display: flex; */
	align-items: center;
	/* height: 35px; */
	/* margin-top: 3em; */
	/* padding: 0px 50px; */
	/* padding: 0px 0px 0px 50px; */
	border-radius: 6px;
	/* margin-bottom: 50em; */
	/* background: #E8E8E8; */
}
.topLeft {
	display: flex;
	align-items: flex-end;
	width: 30em;
	height: var(--heights);
	/* outline: solid 1px rgba(255, 0, 0, 0.295); */
}
.groupNavContainer {
	display: flex;
	align-items: center;
	width: 100%;
	height: 35px;
	background: #E8E8E8;
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px;
}
.groupNavLabel {
	flex-grow: 1;
}
.groupNav {
	display: flex;
}
.btnSystemGroup {
	position: relative;
	display: flex;
	justify-content: center;
	width: 3em;
	margin-right: 0.7em;
	background: #015488;
	border-radius: 6px;
	cursor: pointer;
	transition: all .3s;
}
.btnSystemGroup:hover {
	background: #0081d0;
}
.btnIcon {
	padding: .3em 0;
	color: #ffffff;
}
.btnSlidingLabel {
	position: absolute;
	display: flex;
	justify-content: center;
	top: 0px;
	width: 7em;
	font-size: 10px;
	color: black;
	transition: all .25s;
	z-index: -1;
}
.btnSystemGroup:hover .btnSlidingLabel {
	top: -16px;
}

.topRight {
	display: flex;
	align-items: flex-end;
	height: var(--heights);
	/* outline: solid 1px rgba(255, 0, 0, 0.295); */
}
.systemGroup {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.5em;
	height: 35px;
	background: #E8E8E8;
	border-left: solid 1px #DDDDDD;
}
.systemGroupCheckbox {
	font-size: 1.3em;
	cursor: pointer;
}
.systemGroupIcon {
	padding: 0 2em;
}
.systemGroupNameContainer {
    position: absolute;
	width: 13em;
	height: 3em;
    transform: translate(33%, 13%) rotate(-40deg);
    transform-origin: left top;
    z-index: -1;
}
.systemGroupName {
	display: flex;
    width: 100%;
	margin: 1em 0 0 3.5em;
	font-size: .9em;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.systemGroupName svg {
    font-size: 1.2em;
    color: #ff9a00;
    margin-right: .5em;
}
.systemGroupLine {
	height: 1px;
	background: linear-gradient(90deg, #DDDDDD, white);
}



/* =========  System Groups RIGHT ========= */
.systemGroupsRight {
	overflow: auto;
	width: 100%;
}
/* .systemGroupsRight::-webkit-scrollbar {
    width: 30px;
    height: 30px;
} */
.bottomLeft {
	width: 30em;
	/* outline: solid 1px rgba(255, 0, 0, 0.295); */
}
.systemName {
	flex-grow: 1;
	width: 15em;
	font-size: .9em;
	font-weight: bold;
	text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.systemRow {
	display: flex;
	align-items: center;
}
.systemRowLeft {
	position: relative;
	display: flex;
	align-items: center;
	width: 30em;
	height: 2.5em;
	border-bottom: solid 1px #DDDDDD;
}

.bottomRight {
	/* color: red; */
	/* outline: solid 1px rgba(255, 0, 0, 0.295); */
}
.configRow {
	display: flex;
	height: 2.5em;
}
.configLabel {
	display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 2.5em;
	padding-right: .7em;
	color: var(--lightGray);
}
.configBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.5em;
	height: 2.2em;
	margin: 0em 0px .15em 0;
	border-left: solid 1px #DDDDDD;
}
.configBoxInner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 5.2em;
	height: 2.2em;
	/* background: #F5F5F5; */
	/* border: solid 2px #F5F5F5; */
	/* border-radius: 6px; */
	/* cursor: pointer; */
}
.configName {
	font-size: 1.1em;
	color: #015488;
}


.systemRowRight {
	display: flex;
	flex-grow: 2;
}
.rolloverBox {
    display: none;
    position: absolute;
    bottom: 0.5em;
    left: 2em;
	width: 22em;
    padding: 1.5em;
	font-size: .7em;
	font-weight: 600;
	line-height: 1.4;
	color: var(--black);
    background: white;
    border: solid 1px black;
    border-radius: .5em;
    z-index: 20;
}
.rolloverHeader {
	margin-bottom: .5em;
	font-weight: 700;
	color: var(--darkBlue);
}
.systemInfo,
.configInfo {
	position: relative;
	display: inline-block;
	margin-right: 0.3em;
	font-size: 1.2em;
	color: #015488;
	cursor: pointer;
}
.systemInfo:hover .rolloverBox,
.configInfo:hover .rolloverBox {
	display: block;
}

.selectBox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.5em;
	height: 2.2em;
	margin: .15em 0px .15em 0;
}
.selectBoxInner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.2em;
	height: 2.2em;
	background: #F5F5F5;
	border: solid 2px #F5F5F5;
	border-radius: 6px;
	cursor: pointer;
}
.iconChecked {
	font-size: 1.3em;
	color: #328940;
}
.iconLocked {
	font-size: 1.3em;
	color: #FFFFFF;
}
.iconPending {
	font-size: 1.3em;
	color: gold;
}
.iconMaster {
	font-size: 1.3em;
	color: red;
}
.selectedGroup {
	background: #E4F7E4;
	border: solid 2px #D1EDD1;
	transition: all .3s;
}
.selectedGroup:hover {
	background: #ccf9cc;
	border: solid 2px #9ee09e;
}
.notSelectedGroup {
	background: #F5F5F5;
	border: solid 2px #f0f0f0;
	transition: all .3s;
}
.notSelectedGroup:hover {
	background: #e6e6e6;
	border: solid 2px #e6e6e6;
}
.groupPending {
	background: #ffffa8;
	border: solid 2px #ffecac;
	transition: all .3s;
}
.groupPending:hover {
	background: #ffff77;
	border: solid 2px #f5d15c;
}
.groupMaster {
	background: #fdd9d9;
	border: solid 2px #ffc9c9;
	transition: all .3s;
}
.groupMaster:hover {
	background: #fbc4c4;
	border: solid 2px #fba5a5;
}
.lockedSelectBoxInner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 4.2em;
	height: 2.2em;
    background: #e3e3e3;
    border: solid 2px #dedede;
	border-radius: 6px;
}
.lockedSelectGroup {
	background: #888888;
	border: solid 2px #777777;
}

/* Modal */
.modalContent {
	padding: 1.5em 2em;
}

.modalContent.normalizePadding {
	padding: 1.5em 2em;
}

.modalBottomButtons {
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 0 30px;
}
.modalBottomButtons button {
	margin-left: 1em;
}

/* =========  Pending Changes Modal ========= */
.buttonContainer{
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 100px;
}

.buttonContainer button {
	max-width: 50%;
	min-height: 33%;
}

/* ======================================= */
/* ============ MEDIA QUERIES ============ */
/* ======================================= */
@media (max-width: 1200px) {
	.systemGroupNameContainer {
		width: 15em;
	}
	.configBoxInner {
		width: 5.2em;
	}
	.topLeft,
	.bottomLeft {
		width: 26em;
	}
}
@media (max-width: 1100px) {
	.systemGroupNameContainer {
		width: 16em;
	}
	.configBoxInner {
		width: 5.2em;
	}
	.topLeft,
	.bottomLeft {
		width: 24em;
	}
}
@media (max-width: 1000px) {
	.systemGroupNameContainer {
		width: 16em;
	}
	.configBoxInner {
		width: 4.4em;
	}
	.topLeft,
	.bottomLeft {
		width: 20em;
	}
	.btnSystemGroup {
		margin-right: .4em;
	}
}