.popoverBackground {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.viewWindow {
    /* display: none; */
    z-index: 1000;
    position: absolute;
    top: 18.5em;
    left: 17.5em;
    overflow-y: scroll;
	width: 33vw;
    min-width: 480px;
    max-height: 60vh;
    padding: 1.3em;
	/* font-size: .7em; */
	font-weight: 600;
	line-height: 1.4;
	/* color: var(--black); */
    background: white;
    border: solid 1px black;
    border-radius: .5em;
    z-index: 20;
    display: flex;
    flex-direction: column;
    left: .25em;
}

.closeViewWindow {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* margin-bottom: -37.5px; */
}
.closeViewWindow button {
    font-size: 1.2em;
    color: var(--black);
    background: none;
    border: none;
    cursor: pointer;
}
.closeViewWindow button:hover {
    color: black;
}

.viewHeader {
    font-size: 1.2em;
    font-weight: 700;
    color: var(--black);
    margin-right: 10px;
    padding: 0 0 5px 0;
}

.toggleButton{
    margin-right: 10px;
}
.editButtonCont {
    display: inline-block;
    margin-left: .3em;
    cursor: pointer;
}
.editButtonCont:hover svg {
    color: #009fda;
}

.viewHeaderContainer{
    display: flex;
    flex-direction: row;
}

.thFilter{
    margin-left: 40%;
    margin-top: 10px;
    border: none;
    border-bottom: 1px solid #333;
}
.thFilter:active, .thFilter:focus-visible{
    border: none;
    border-bottom: 1px solid #000;
    outline: none;
}