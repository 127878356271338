:root {
	--heights: 14em;
	--darkGray2: #707070;
	--darkGray3: #555555;
	--darkGray: #434343;
	--lightGray: #808080;
}
h2 {
	width: 100%;
	margin: 0;
	padding: 0;
	font-size: 1.75em;
	font-weight: 800;
	color: #5a5a5a;
	text-transform: uppercase;
}
h3 {
	margin: 0;
	color: white;
}
.disabledSquare {
	font-size: 1em;
	color: rgb(206, 206, 206);
}
.activeCheckbox {
	font-size: 1em;
}

/* Modal */


.infoBox {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    margin: 10px 0;
}

.infoBox:first-child {
    margin: 0 0 10px 0
}

.componentSelector {
    width: 320px;
    width: 20em;
    height: calc(100vh - 64px);
	padding: 2em 1.5em 1.5em 1.5em;
	color: var(--darkGray);
    background: #F0F2F5;
}

.formHeader {
    width: 100%;
    background-color: var(--black);
    margin: 0;
    padding: 15px 30px;
    border-radius: 4px 4px 0 0;
}

.formHeader h2 {
    width: 100%;
    color: var(--white);
    margin: 0;
    padding: 0;
}

.sectionLabel {
	margin-bottom: .3125em;
	color: var(--darkBlue);
	font-weight: 700;
	font-size: 1.2em;
	text-transform: uppercase;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 15px 30px;
}

.infoBoxUploader {
    width: 100%;
    height: auto;
    order: 100;
}

.listingsTableImage{
    width: auto;
    max-height: 2em;
    margin: 0 auto;
}

.listingsModal {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0;
    overflow: auto;
}

.listingsModalContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 1em 1em 0em 1em;
}

.integrationModalContainer input {
    background-color: #f2f2f2; 
    color: #888; 
    cursor: not-allowed; 
    pointer-events: none;
}

.integrationModalContainer button {
    background-color: var(--lightGrey);
	border: 2px solid var(--lightGrey);
	cursor: not-allowed;
    pointer-events: none;
}

.listingsModal h2 {
    width: 100%;
    color: var(--darkBlue);
}

.infoTitle {
    font-weight: 700;
}

.tableContainer {
    width: 200%;
    display: flex;
    flex-direction: column;
}

.tableRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tableItem {
    flex: 1
}

.top {
    width: 100%;
    font-size: 16px;
    color: var(--darkGrey);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 15px;
}

.top span {
    text-decoration: underline;
    cursor: pointer;
    transition: all .5s;
}

.top span:hover {
    color: var(--black);
}

.label {
    width: 100%;
}

.column1 {
    width: 100%;
    padding: 0 0 15px 0;
}

.uploader {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0;
}

.uploaderImage {
    position: relative;
	width: 100%;
	height: 400px;
	float: left;
	background-color: var(--lightGrey);
	padding: 15px;
	border: 1px solid var(--darkGrey);
	border-radius: 4px;
	overflow: hidden;
}

.uploaderImage img {
    max-width: inherit;
	width: 100%;
	height: 100%;
	object-fit: scale-down;
}

.uploaderFileName {
    width: 30%;
    height: 100%;
    font-size: 10px;
    font-weight: bold;
    color: var(--darkGrey);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 10px 0 0 0;
}

.uploaderButtons {
    width: 68%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 10px 0 0 0;
}

.uploaderButtons button {
    margin: 0 0 0 10px;
}

.description {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}

.column2 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px 0;
}

.contentControls {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    color: var(--darkGrey);
}

.contentControls span {
    color: var(--black);
}

.contentControls svg {
    color: var(--lightGrey);
    margin: 5px;
    transition: all .5s;
    cursor: pointer;
}

.contentControls svg:hover {
    color: var(--accent);
}

.contentFilter {
	width: 100%;
	font-size: 1em;
	margin: 0 0 15px 0;
	padding: 5px;
	border: 1px solid var(--lightGrey);
	border-radius: 5px;
}

.contentAssign {
    width: 100%;
    height: auto;
    min-height: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    background-color: #f7f7f7;
    margin: 0;
    padding: 0 10px 10px 10px;
    overflow: auto;
}

.groupName {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.childGroupName {
    flex-direction: column;
    padding-bottom: .3em;
}

.contentBucket {
    width: 100%;
    padding: 0;
}

.contentBucketParent {
    padding: .5em 0 .5em 0;
}

.groupName:not(:first-child) .contentBucketParent {
    border-top: 1px solid var(--darkGrey);
}

.contentAssign li svg {
    font-size: 1.5em;
    margin: 0 10px 0 0;
}

.contentGroupName {
    width: 100%;
    padding: .5em 0 .5em 2em;
    align-self: flex-end;
    color: #00BAFF;
    margin-top: -.7em;
}

.nameContainer {
    display: flex;
    width: 100%;
    align-items: center;
    padding: .2em 0;
}

.nameContainerParent {
    padding-top: 0;
}

.hide {
    display: none;
}

.show {
    display: flex;
}

.scheduling {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.contentToggle {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 0 15px 0;
}

.contentToggle input {
    margin: 0 15px 0 0;
}

.available {
    width: 100%;
    display: flex;
}

.notAvailable {
    width: 100%;
    display: none;
}

.notAvailable input {
    pointer-events: none;
}

.datePicker {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 30px 0;
}

.startDate, .endDate {
    width: 49%;
}

.timePicker {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0 30px 0;
}

.timeDate, .timeDate {
    width: 49%;
}

.dayPicker {
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 15px 0 30px 0;
}


.days {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: 0 0 5px 0;
}

.day {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 20px;
	min-width: 20px;
	max-width: 20px;
	height: 20px;
	min-height: 20px;
	max-height: 20px;
	margin: 0 1px;
	color: var(--white);
	font-size: .6em;
	font-weight: bold;
	background-color: #989898;
	border-radius: 50%;
}

.day:first-child {
	margin: 0 1px 0 0;
}

.times {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.dates {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}
.bottom {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 0 30px;
    border-top: 1px solid var(--black);
}

.bottom button {
    margin: 0 0 0 15px;
}

.clearButton, .multiselectButton {
	color: var(--darkBlue);
	background-color: transparent;
	font-size: .8em;
	font-weight: bold;
	border: 0px;
	outline: 0;
	box-shadow: none;
	text-decoration: underline;
	cursor: pointer;
	transition: all .5s;
}

.multiselectButton {
	margin: 1em 1em 3em 0;
	padding: 0;
}

.clearButton:hover {
	color: var(--darkBlueHover);
}

.popoverBackground {
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}
.tagsWindow {
    /* display: none; */
    z-index: 1000;
    position: absolute;
    top: 18.5em;
    left: 17.5em;
	width: 25vw;
    padding: 1.5em;
	font-size: .7em;
	font-weight: 600;
	line-height: 1.4;
	color: var(--black);
    background: white;
    border: solid 1px black;
    border-radius: .5em;
    z-index: 20;
}
.viewWindow {
    display: flex;
    flex-direction: column;
    left: .25em;
}
.viewWindowContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.unassignedListingsHeader{
    display: flex;
    width: 100%;
    gap: 0.5em;
    margin: 0 0 1em 0;
}
.unassignedListingsHeader i {
    color: red;
}
.dataColumn, .contentGroupsColumn {
    width: 40%;
}
.viewWindow h5 {
    font-size: 1.25em;
    margin: 0;
    padding: 10px 0;
}
.dataColumnWidth {
	display: flex;
	align-items: center;
	width: 4em;
}
.tagElement {
    display: flex;
    align-items: center;
    margin-bottom: .4em;
    font-size: .9em;
    font-weight: 500;
}
.tagElement svg {
    margin: 0 5px 0 0;
}
.editLabelWindow {
    left: 9.5em;
}
.closeTagsWindow {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* margin-bottom: -37.5px; */
}
.closeTagsWindow button {
    font-size: 24px;
    color: var(--accent);
    background-color: transparent;
    border: 0px;
    cursor: pointer;
    transition: all .25s;
}
.closeTagsWindow button:hover {
    color: var(--accentDark);
}
.tagsHeader {
    width: 100%;
	font-size: 1.5em;
	font-weight: 700;
	color: var(--black);
    padding: 0 0 5px 0;
}
.myTags {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0 20px 0;
    flex-wrap: wrap;
}
.contentGroupTags {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px 0 20px 0;
}
.tag {
    width: auto;
    /* height: 1em; */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-weight: bold;
    font-size: 1em;
    margin: 0 5px 5px 0;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
    transition: all .25s;
}
.tag:hover {
    opacity: .75;
}
.searchTag {
    font-size: .6em;
    padding: 5px 5px 5px 10px;
}
.searchTag svg {
    font-size: 1.25em !important;
    color: var(--white);
}

.assignTags {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}
.assignRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 5px 0;
}
.assignCheckbox {
    width: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.assignCheckbox svg {
    font-size: 20px;
}
.assignIcon {
    width: 3em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.assignIcon svg {
    font-size: 20px;
}
.assignName {
    height: 2em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--black);
    font-weight: bold;
    font-size: 1em;
}
.editLabel {
    height: 100%;
    font-size: 1.4em;
    color: var(--midGrey);
    margin: 0 0 0 .5em;
    transition: all .5s;
    cursor: pointer;
}
.editLabel:hover {
    color: var(--accent);
}
.editLabelContent {
    width: 100%;
    padding: 15px;
}
.editLabelButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 15px;
}
.editLabelButtons button {
    margin: 0 0 0 5px;
}

.saveTags {
    position: relative;
    width: 100%;
    padding: 15px 0;
}

.inputLabel {
	width: 10em;
}

.input {
	width: 3em;
	margin: 0 .3em;
	text-align: center;
	border: solid 1px gray;
	border-radius: 3px;
}

.inputSmall {
	width: 3em;
	margin: 0 .3em;
	/* margin-right: .1em; */
	text-align: center;
	border: solid 1px gray;
	border-radius: 3px;
}

.devRow {
	display: flex;
	align-items: center;
	padding-right: 2em;
}

.sectionHeader {
	display: flex;
	align-items: center;
}
.sectionHeaderText {
	margin: .5em .5em .4em 0;
	font-weight: 700;
}
.contentGroupSettings {
	display: flex;
	align-items: center;
}
.contentGroupWidth {
	display: flex;
	align-items: center;
}
.saveSettingsButton {
	width: 20%;
	display: flex;
	justify-content: center;
	padding-top: 1em;
}

.colDirectionsCont {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 19em;
	margin-top: 1em;
	padding: .3em 0;
	font-size: .7em;
	color: #484848;
	background: #e4e4e4;
	border-radius: 1em;
}
.colDirectionsContError {
	color: white;
	background: red;
}

.checkContainer {
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-left: 0;
}
.checkContainer .checkbox{
    font-size: 1.5em;
    margin: 0 10px 0 0;
}

.listingAdd{
    margin: auto 0;
}

.buttonsContainer{
    display: flex;
    flex-direction: column;
}
.toggleButton{
    margin-bottom:10px;
}

.thFilter {
	width: 100%;
}

.thFilter input {
	box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	font-weight: 700;
	line-height: 1.5em;
	color: var(--white);
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #bdbdbd;
	outline: 0;
	transition: border .3s;
}
@media (min-width: 992px) {
    .column1 {
        width: 49%;
        height: 90%;
        padding: 0 0 30px 15px;
    }

    .column2 {
        width: 49%;
        padding: 10px 15px 30px 0;
    }
}

@media (min-width: 1400px) {
    .infoBox {
        width: 50%;
        margin: 0;
    }
}

@media (min-width: 1600px) {
    .infoBox {
        width: 33.3%;
    }
}

.landmarkIDColumnContainer {
    width: 100%;
    height: 100%;
    padding: 1em 1em 0em 1em;
}

.landmarkIDColumn {
    display: flex;
    width:   100%;
}

.landmarkIDColumn div:first-child {
    width: 80%;
}