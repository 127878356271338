.buttonContainer{
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	height: 100px;
}

.buttonContainer button {
	max-width: 50%;
	min-height: 33%;
}