.modalWrapper {
    z-index: 998;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.9);
}

.modal {
    z-index: 999;
    position: fixed;
    width: 90vw;
    height: 90%;
    top: 50%;
    left: 50%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    border: solid 2px white;
    border-radius: .5em;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}
.large {
	width: 90vw;
	max-width: 1920px;
	height: 90vh;
	max-height: 1080px;
	padding: 0 0 2em 0;
}
.medium {
	width: 75vw;
	max-width: 1024px;
	height: 75vh;
	max-height: 768px;
	padding: 0 0 2em 0;
}
.small {
	width: 30vw;
	max-width: 480px;
	height: auto;
	/* padding: 0 0 2em 0; */
}
.custom {
    width: 50vw;
    max-width: 720px;
    max-height: 400px;
}
.flexible {
    width: unset;
    height: unset;
    min-width: 25vw;
    min-height: 35vh;
    max-width: 90vw;
    max-height: 90vh;
}
.mobile_preview {
    width: unset;
    height: unset;
    min-height: 35vh;
    max-width: 90vw;
    max-height: 90vh;
}
.modalContainer {
    width: 100%;
    height: 100%;
    /* min-height: 100%; */
    /* display: flex; */
    /* flex-direction: column; */
}

.modalHeader {
    width: 100%;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 .5em 0 1em;
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    border-radius: 4px 4px 0 0;
}

.modalHeader h1 {
    color: var(--white);
    margin: 0;
    padding: 15px;
}

.modalClose {
    padding: 0em 1em;
}

.modalClose svg {
    font-size: 24px;
    color: var(--white);
    cursor: pointer;
    transition: all .5s;
}

.modalClose svg:hover {
    color: var(--accent);
}

.modalContent {
    width: 100%;
    height: calc(100% - 50px);
    /* flex: 1; */
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: center; */
    /* justify-content: flex-start; */
    /* overflow: hidden; */
}

.modalClosed {
    display: none;
}

.modalOpen {
    display: block;
}