.builderPage {
    display: flex;
    flex-direction: column;
    width: auto;
    height: 100vh;
    padding: 4em 1em 3em 5em;
    transition: all .3s;
    overflow: auto;
}
.title {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.5em 0 0 0;
}
.title :global(.button) {
    margin-left: 1em;
}
.secondaryInfo {
    display: flex;
    margin: 0 0 2em 0;
    flex-direction: column;
    align-items: flex-start;
}
.categoryName {
    font-size: 1.25em;
    font-weight: 700;
    color: #545454;
}
.categoryNameSpacer {
    margin: 0 .4em;
    font-weight: 400;
}
.configName {
    margin-top: 1em;;
    padding: 0 .5em 0 .5em;
    font-size: .825em;
    font-weight: 400;
    color: black;
    line-height: 1.5em;
    border: 1px solid #d0d0d0;
    border-radius: 4px;
    outline: 0;
    transition: border .3s;
}
.configName:hover {
    border: 1px solid var(--accent);
}
