:root {
	--white: #fff;
	--darkBlack: #000;
	--blackHover: #424242;
	--black: #525252;
	--darkGrey: #808080;
	--midGrey: #B5B5B5;
	--lightGrey: #e2e2e2;
	--offWhite: #eee;
	--blueWhite: #e5f6fc;
	--darkBlue: #015488;
	--darkBlueHover: #007fcc;
	--accentDark: #0f80a9;
	--accent: #00BAFF;
	--accentLight: #7cd6f7;
}

* {
	box-sizing: border-box;
}

html {
	overflow: hidden;
}

body {
	font-size: 16px;
	background-color: var(--white);
	font-family: 'Montserrat', sans-serif;
	font-weight: 500;
}
input {
	font-family: 'Montserrat', sans-serif;
}
::selection {
	/* color: var(--paper);
	background-color: var(--accentDark); */
}

[data-onclick] * {
	pointer-events: none;
}

/* Tooltip */
.tooltip {
	width: fit-content;
	color: var(--darkGrey);
	font-size: 10px;
}
.important {
	color: var(--accent);
	font-weight: 700;;
}
.note {
    color: firebrick !important;
}

/* Error */
.error {
	color: firebrick !important;
	fill: firebrick !important;
}
.error svg,
.errorIcon svg {
	color: firebrick !important;
	fill: firebrick !important;
}

/* No click */

.no-click {
    pointer-events: none;
}

/* Loader */
.loader_overlay { 
	width: 100%;
	height: 100vh;
	z-index: 99999999;
	position: fixed;
}
.loader-opacity {
	position: fixed;
	z-index: 99999;
	background: rgba(255, 255, 255, 0.8);
	display: block;
	width: 100%;
	height: 100%;
	top: 1px;
	left: 0;
}
.loader_wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}
.loader {
	display: inline-block;
	margin-right: 10px;
	position: relative;
	height: 50px;
	width: 50px;
	vertical-align: middle;
}
.loader.loader-bars:before {
	content: '';
	position: absolute;
	left: 0;
	top: 5px;
	background: #00abf2;
	height: 30px;
	width: 10px;
	-webkit-animation: 1s animeBars linear infinite;
	animation: 1s animeBars linear infinite;
}
.loader.loader-bars:after {
	content: '';
	position: absolute;
	left: 15px;
	top: 5px;
	background: #00abf2;
	height: 30px;
	width: 10px;
	-webkit-animation: 1s animeBars linear -.75s infinite;
	animation: 1s animeBars linear -.75s infinite;
}
.loader.loader-bars span:before {
	content: '';
	position: absolute;
	left: 30px;
	top: 5px;
	background: #00abf2;
	height: 30px;
	width: 10px;
	-webkit-animation: 1s animeBars linear -0.5s infinite;
	animation: 1s animeBars linear -0.5s infinite;
}
.loader.loader-bars span:after {
	content: '';
	position: absolute;
	left: 45px;
	top: 5px;
	background: #00abf2;
	height: 30px;
	width: 10px;
	-webkit-animation: 1s animeBars linear -0.25s infinite;
	animation: 1s animeBars linear -0.25s infinite;
}
@-webkit-keyframes animeBars {
	from {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		opacity: 0;
	}
	50% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		opacity: 1;
	}
	to {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		opacity: 0;
	}
}
@keyframes animeBars {
	from {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		opacity: 0;
	}
	50% {
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		opacity: 1;
	}
	to {
		-webkit-transform: scaleY(0);
		transform: scaleY(0);
		opacity: 0;
	}
}

.custom-loader-message-container h2 {
	font-family: "Open Sans", sans-serif;
	font-size: 30px;
	color: #00aeef;
	text-transform: uppercase;
	font-weight: normal;
	margin: 15px 0 0 0;
}

.custom-loader-message-container p {
	text-align: center;
}


/* Expanded / collapsed */
#sidebar.collapsed {
	width: calc(3em + 1px);
}

.expanded {
	left: 0;
	color: red;
	background: red;
}

.collapsed .sidebar-toggle {
	transform: rotate(180deg);
}

/* Save button */

.crow-form-submit-btn-container {
	position: absolute;
	display: none; /* important: this was breaking layout */
	top: 0;
	height: 0;
	height: 100px;
	visibility: hidden;
	z-index: -1;

}

/* Content Groups */
.contentGroups-page {
	display: flex;
	width: auto;
	padding: 4em 0px 0px 3em;
	transition: all .3s;
}

.contentGroups-page .settings-save {
	position: absolute;
    right: 2em;
    top: 88px;
}
.noPermissionsWarning {
    display: flex;
    width: 100%;
    font-weight: bold; /* Makes the font bold */
    color: red; /* Sets the font color to red */
}

/* Secondary Nav */
.secondaryNav {
    display: flex;
    width: 100%;
    /* margin: 4em 0 0 0; */
    /* font-size: .9em; */
}
.secondaryNav .button {
    margin: 0 .3em;
	height: 2em;
}
.secondaryBtn {
    display: flex;
    align-items: center;
    margin-right: .75em;
    padding: .3125em 1em;
    background: #015488;
    color: white;
    border-radius: .4em;
    cursor: pointer;
}
.secondaryBtn:hover {
    background: #1584ca;
}
.secondaryBtnIcon {
    padding-right: .5em;
}
.secondaryBtnText {
    font-weight: 700;
}

/* Listings */
.listings-page {
	display: flex;
	width: auto;
	padding: 64px 0px 0px 0px;
	font-size: 16px;
	transition: all .3s;
}
.listings-page.collapsed {
	margin-left: 20em;
}
.listings-page.expanded {
	margin-left: 31em;
}


/* Systems page styles */
.systems-page {
	width: auto;
	height: 100vh;
	display: flex;
	flex-direction: column;
	padding: 4em 1em 3em 5em;
	/* transition: all .3s; */
	overflow: auto;
}

/* Settings page styles */
.settings-page {
	position: relative;
    /* width: calc(100% - 24em); */
    width: calc(100% - 21vw);
	height: 98vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
	/* margin-left: 24em; */
	margin-left: 21vw;
	padding-top: 4em;
	overflow: hidden;
    /* transition: all .3s; */
}

.settings-header {
	position: relative;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 1.5em 2em 4em 0;
}

.settings-content {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 1.5em 0 0em 0;
	padding: 1.5em 0;
	overflow-y: auto;
}
.settings-save {
	/* padding: 0 1em; */
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
}

/* Buttons */
.sub-header-buttons {
	position: absolute;
	display: flex;
	right: 0;
	bottom: 0;
	margin-right: 1em;
}

.button {
	display: flex;
	width: auto;
	padding: .25em 1em;
	font-family: unset;
	font-size: .9em;
	font-weight: 700;
	color: var(--white);
	align-items: center;
	background-color: var(--darkBlue);
	border: 2px solid var(--darkBlue);
	border-radius: 4px;
	box-shadow: none;
	transition: all .3s;
	cursor: pointer;
	outline: none;
	white-space: nowrap;
}

.button:hover {
	background-color: var(--darkBlueHover);
	border: 2px solid var(--darkBlueHover);
}

.button-white {
	display: flex;
	width: auto;
	padding: .25em 1em;
	font-family: unset;
	font-size: .9em;
	font-weight: 700;
	color: var(--darkBlue);
	align-items: center;
	background-color: var(--white);
	border: 2px solid var(--white);
	border-radius: 4px;
	box-shadow: none;
	transition: all .3s;
	cursor: pointer;
	outline: none;
}

.button-white:hover {
    background-color: var(--offWhite);
	border: 2px solid var(--offWhite);
}

.button-outlined {
	width: auto;
	font-size: .9em;
	font-weight: 700;
	/* text-transform: uppercase; */
	background-color: transparent;
	color: var(--accent);
	padding: .3125em 1em;
	border: 2px solid var(--accent);
	border-radius: 4px;
	box-shadow: none;
	transition: all .3s;
	cursor: pointer;
}
.button-outlined:hover {
	color: var(--white);
	background: var(--darkBlueHover);
    border: 2px solid var(--darkBlueHover);
}
.button-outlined-dark {
	width: auto;
	font-size: .9em;
	font-weight: 700;
	/* text-transform: uppercase; */
	background-color: transparent;
	color: var(--darkBlue);
	padding: .3125em 1em;
	border: 2px solid var(--darkBlue);
	border-radius: 4px;
	box-shadow: none;
	transition: all .3s;
	cursor: pointer;
}
.button-outlined-dark:hover {
	color: var(--white);
	background: var(--darkBlue);
    border: 2px solid var(--darkBlue);
}
.button-save-lg {
	font-size: 1.3em;
	text-transform: uppercase;
	background: var(--accent);
	border: 2px solid var(--accent);
}
.button-save-lg:hover {
	font-size: 1.3em;
	background: var(--accentDark);
	border: 2px solid var(--accentDark);
}
.bumpDown {
	margin-top: .1em;
}
.button-disabled, .button-disabled:hover {
	background-color: var(--lightGrey);
	border: 2px solid var(--lightGrey);
	cursor: not-allowed;
}

.button svg,
.button-outlined svg,
.button-disabled svg {
	margin: 0 .5em 0 0;
}
.button-hide {
	display: none;
}

/* Fade in */
.fade-in {
	animation: fadeIn ease .3s;
}
@keyframes fadeIn {
	0% {
		opacity:0;
	}
	100% {
		opacity:1;
	}
}

.alert-button {
	background-color: firebrick;
	border: 2px solid firebrick;
}
.alert-button:hover {
	background-color: #6c1414;
	border: 2px solid #6c1414;
}
.mar-rt-0 {
	margin-right: 0.5em;
}

/* Filter */
.filter {
	box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	font-weight: 700;
	line-height: 1.5em;
	color: var(--black);
	border: none;
	border-bottom: 1px solid #bdbdbd;
	outline: 0;
	transition: border .3s;
}

/* Form elements */

.crow-field {
	width: 100%;
}

.crow-field label {
	color: var(--black);
	font-weight: bold;
}

/* Entity */

.entity {
	width: 100%;
}

/* Label */

.label {
	width: 100%;
	padding: .25em 0;
}

.label label {
	font-size: .875em;
	font-weight: 600;
	color: var(--black);
}

/* Time */

.timefield-wrapper {
    padding: 10px 0;
}

/* Text small */

.text-small {
	width: 100%;
	padding: 10px 0;
}

.text-small input[type="text"] {
	box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	font-weight: 400;
	line-height: 1.5em;
	color: black;
	padding: .25em 1em .25em 1em;
	border-radius: 4px;
	border: 1px solid #d0d0d0;
	outline: 0;
	transition: border .3s;
}

.text-small input[type="number"] {
	box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	font-weight: 400;
	line-height: 1.5em;
	color: black;
	padding: .25em 1em .25em 1em;
	border-radius: 4px;
	border: 1px solid #d0d0d0;
	outline: 0;
	transition: border .3s;
}

.text-small input[type="text"]::placeholder {
	color: var(--lightGrey);
}

.text-small input[type="text"]:hover {
	border: 1px solid var(--accent);
}

.text-small input[type="text"]:focus {
	border: 1px solid var(--accent);
	background: #f4fbff;
}

/* Number */

.number {
	width: 100%;
	padding: 10px 0;
}

.number input[type="number"] {
	box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	line-height: 1.5em;
	color: var(--black);
	padding: 5px;
	border-radius: 4px;
	border: 1px solid var(--darkGrey);
	outline: 0;
	transition: border .3s;
}

.number input[type="number"]::placeholder {
	color: var(--lightGrey);
}

.number input[type="number"]:hover {
	border: 1px solid var(--accent);
}

.number input[type="number"]:focus {
	border: 1px solid var(--accent);
}

/* Float */

.float {
	width: 100%;
	padding: 10px 0;
}

.float input[type="number"] {
	box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	line-height: 1.5em;
	color: var(--black);
	padding: 5px;
	border-radius: 4px;
	border: 1px solid var(--darkGrey);
	outline: 0;
	transition: border .3s;
}

.float input[type="number"]::placeholder {
	color: var(--lightGrey);
}

.float input[type="number"]:hover {
	border: 1px solid var(--accent);
}

.float input[type="number"]:focus {
	border: 1px solid var(--accent);
}

/* Uploader */
.uploaderWrapper {
	padding: 10px 0;
}
.uploader {
	width: 100%;
	position: relative;
	overflow: hidden;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: 0;
	padding: 1.5em 1em;
	border: 1px solid #d0d0d0;
    border-radius: 4px;
}

.uploader-image {
	width: 40%;
	max-width: 12.5em;
	height: 9.375em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: var(--darkGrey);
	background-color: var(--lightGrey);
	border: 1px solid var(--darkGrey);
	border-radius: 4px;
	cursor: pointer;
}

.uploader-buttons input[type=file] {
	position: absolute;
    visibility: hidden;
    height: 0;
}

.uploader-input {
    visibility: hidden;
    height: 5px;
}

.uploader img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	padding: 10px;
	cursor: pointer;
}

.uploader-buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	padding: 2em 0 1em 0;
	justify-content: center;
}

.uploader-buttons button {
	margin: 0 .3em;
}

.uploader-restrictions {
	font-size: .9em;
	font-weight: 600;
	color: var(--darkGrey);
	margin-left: -3em;
}

/* Checkbox */

.checkbox {
    transition: all .3s;
}

.checkbox svg {
    transition: all .3s;
}

.checkbox:hover {
    cursor: pointer;
}

.square svg {
    color: var(--lightGrey);
}

.check-square svg {
    color: var(--accent);
}

/* Advanced Checkbox */

.advanced-checkbox span {
	padding: 0;
}

.advanced-checkbox .MuiCheckbox-colorPrimary {
	color: var(--midGrey);
}

.advanced-checkbox .MuiCheckbox-colorPrimary.Mui-checked {
	color: var(--accent);
}

/* On/Off */

input[type="checkbox"] {
	background: var(--midGrey);
	/* border: 1px solid var(--darkGrey); */
}

input[type="checkbox"]:hover {
	background: var(--darkGrey);
}

input[type="checkbox"]:focus {
	outline: none;
}

.on-off {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	width: 7em!important;
	height: 3em;
	display: inline-block;
	position: relative;
	font-size: 8px;
	color: rgb(255 255 255 / 75%);
	line-height: 2.5;
	background-color: var(--white);
	border: none;
	border-radius: 1.5em;
	cursor: pointer;
	outline: none;
	overflow: hidden;
	transition: background-color ease 0.3s;
}
.on-off-label {
	background: red;
}
.craig {
	background: blue;
}
.on-off:before {
	content: "";
	display: block;
	position: absolute;
	width: 3.5em;
	height: calc(3em - 4px);
	left: 2px;
	top: 2px;
	/* font: 10px/33px sans-serif; */
	/* text-transform: uppercase; */
	/* font-weight: bold; */
	/* text-indent: -18px; */
	/* word-spacing: 33px; */
	/* white-space: nowrap; */
	background: var(--white);
	/* color: var(--white); */
	border-radius: 2em;
	transition: all cubic-bezier(0.3, 1.5, 0.7, 1) 0.3s;
}

.on-off:checked {
	background-color: var(--darkBlue);
}
.on-off:checked:hover {
	background-color: var(--darkBlueHover);
}

.on-off:checked:before {
	left: 26px;
	background-color: var(--white);
	/* color: var(--white); */
}
.on-off-label:after {
    content: "ON OFF";
    position: absolute;
    color: var(--white);
    font-family: 'Montserrat';
    font-weight: 700;
    text-indent: 8px;
    line-height: 3;
    word-spacing: 1.15em;
}
/* Number picker */

.numberPicker {
	width: 100%;
	height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding-bottom: 60px;
}

.numberPicker .label {
	width: 100%;
}

.numberPickerContainer {
	width: 250px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 5px 0 60px 0;
}

.numberPicker button {
	width: 30px;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: var(--darkBlue);
	color: var(--white);
	font-size: 21px;
	font-weight: 500;
	border-radius: 50%;
	border: 1px solid var(--darkBlue);
	padding: 5px 10px 5px 10px;
	cursor: pointer;
}

.numberPicker input {
	font-size: 1.5em;
	color: var(--darkBlue);
	width: 10rem;
	margin: 0 .5rem;
	text-align: center;
}

.numberPicker button:first-child {
	padding: 0 0 2.5px 0;
}

.numberPicker button:hover {
	border: 1px solid transparent;
}

.numberPicker button:active {
	border: 1px solid var(--accent);
	outline: 3px solid var(--accent);
}

.numberPicker span {
	font-size: 1.5em;
	color: var(--darkBlue);
	padding: 0 10px 0 10px;
}

.numberPicker span input {
	font-family: inherit; /* Match the font-family to your surrounding text */
	font-size: inherit; /* Match the font-size to your surrounding text */
	font-weight: inherit; /* Match the font-size to your surrounding text */
	color: inherit; /* Match the text color to your surrounding text */
	border: none; /* Remove the border */
	outline: none; /* Remove the focus highlight */
	background-color: transparent; /* Make the background transparent */
	width: auto; /* Set the width to auto to only take up as much space as it needs */
	text-align: center; /* Center the text */
	-moz-appearance: textfield; /* Remove spinner in Mozilla */
	margin: 0;
	padding: 0;
	width: 3vw; /* So that three digits fit well into it*/
}

.numberPicker span input::-webkit-outer-spin-button,
.numberPicker span input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.numberPicker button.button-disabled {
    background-color: var(--lightGrey);
	border: 1px solid var(--lightGrey);
}

/* Datepicker */

.react-datepicker-popper {
	z-index: 1000;
	position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] {
	z-index: 1000;
}

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker-wrapper input {
	box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	line-height: 1.5em;
	font-weight: 400;
	color: var(--black);
	padding: .25em 1em;
	border-radius: 4px;
	border: 1px solid #d0d0d0;
	outline: 0;
	transition: border .3s;
}

.react-datepicker-wrapper input[type="text"]::placeholder {
	color: var(--lightGrey);
}

.react-datepicker-wrapper input[type="text"]:hover {
	border: 1px solid var(--accent);
}

.react-datepicker-wrapper input[type="text"]:focus {
	border: 1px solid var(--accent);
}

.timefield-wrapper{
	position: relative;
}
.timefield-wrapper button{
	position: absolute;
	right: 5px;
    bottom: 5px;
    padding: 0.125em 0.25em;
}

.timefield-wrapper svg{
	margin: 0;
}

/* Weekday selector */
.weekday-selector input {
	display: none!important;
}

.weekday-selector {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: .6em 0 0 0;
}

.weekday-selector input[type=checkbox] + label {
	width: 40px;
	min-width: 40px;
	height: 40px;
	min-height: 40px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	margin: 0 .7em 0 0;
	font-size: .8em;
	border-radius: 50%;
	background: var(--white);
	border: 1px solid var(--lightGrey);
	text-align: center;
	cursor: pointer;
}
.weekday-selector input[type=checkbox] + label:hover {
	background: #edf8ff;
	border: solid 1px var(--accent);
}
.weekday-selector input[type=checkbox]:checked + label {
	background: #4AA046;
	border: solid 1px #257722;
	color: var(--white);
}
.weekday-selector input[type=checkbox]:checked + label:hover {
	background: #788a77;
	border: solid 1px #5a5a5a;
}

/* Custom Alert */

.confirm-modal-container {
	z-index: 1000;
	position: fixed;
	top: 0;
	left:0;
	width: 100%;
	height: 100%;
	display: flex;
	overflow: scroll;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: rgba(255, 255, 255, 0.9);
}
.confirm-modal-content {
	width: 100%;
	max-width: 500px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	color: var(--darkBlue);
	margin: 0 auto;
	padding: 75px 0 0 0;
}
.confirm-modal-content p {
	width: 100%;
	font-size: 1em;
	text-align: center;
}
.confirm-modal-buttons {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	text-align: center;
}
.confirm-modal-buttons button {
	margin: 0 2.5px;
}

.confirmModalButtonContainer{
	display: flex;
	flex-direction: row;
}

/* Phone Number */
.phoneContainer{
	display:flex;
}

.phoneContainer .number{
	font-size: 1em;
    padding: 0.25em 1em 0.25em 1em;
    border-radius: 4px;
    border: 1px solid #d0d0d0;
    transition: border .3s;
    margin-left: 10px;
}

.phoneSelect{
	width:100%;
}

.expirationContainer{
	padding: 20px;
    margin: 70px 5px -4em 5vw;
    text-align: center;
    width: 90%;
}

.yellowwarning{
	background: rgb(255 255 0 / 50%);
	border: 2px solid rgb(197 197 0);
	border-radius: 5px;
	padding: 10px;
	}
.orangewarning{
	background: rgb(255 125 0 / 50%);
	border: 2px solid #bd5a00;
	border-radius: 5px;
	padding: 10px;
	}
.redwarning{
	background: rgb(255 0 0 / 50%);
	border: 2px solid #c30000;
	border-radius: 5px;
	padding: 10px;
}
/* ============================================= */
/* =============== MEDIA QUERIES =============== */
/* ============================================= */
@media (max-width: 1700px) {
	#root {
		font-size: 15px;
	}
}
@media (max-width: 1500px) {
	#root {
		font-size: 14px;
	}
}
@media (max-width: 1300px) {
	#root {
		font-size: 13px;
	}
}
@media (max-width: 1100px) {
	#root {
		font-size: 12px;
	}
}