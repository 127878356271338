.listingContainer {
    display: flex;
    margin: 1.5em 0 3em 0;
}

.listingConfigContainer {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-right: 20px;
}

.listingConfigLabel {
    position: relative;
    background-color: #606060;
}

.listingConfigLabel h1 {
    position:relative;
    text-transform: uppercase;
    margin: 5px;
    padding: 0 5px;
    font-size: 1.25em;
    color: white;
}

.listingConfigLabel span{
    font-weight: 300;
}

.listingConfigCode{
    margin-top:2px;
    margin-left: 10px;
    font-weight: 300;
}

.listingConfigData {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.listingConfigDataRow {
    display: flex;
    position:relative;
    margin: 5px 0;
}

.listingConfigDataRowInput {
    width: 50%;
    margin: 0;
    padding: 0 10px;
}

.listingConfigDataRowInput * {
    padding: 0;
}

.listingConfigDataRowInput > div {
    font-size: .875em;
    font-weight: 600;
    color: var(--black);
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
}

.listingConfigDataRowInput > div > input{
    height: 30px;
}

.listingConfigDataRowInput > div.listingConfigSubcontainer{
    display:flex;
    flex-direction: row;
}

.text-small input{
    padding: 0.15em 1em 0.15em 1em !important;
}

.listingConfigSelectLabel {
    padding: 0;
    flex-grow: 1;
}

.listingConfigSelectLabel > div {
    min-height: 0;
    line-height: 1em;
    padding-left: 5px;
}


.button {
    width: fit-content;
    margin: 5px;
    display: flex;
    justify-content: center;
    margin-left:10px;
}

.deleteIcon {
    display: inline-block;
    align-self: center;
    color: #eee;
}

.listingConfigDataRow .deleteIcon{
    color: #ccc;
    margin-left: 5px;
}

.listingConfigLabel .deleteIcon {
    position: absolute;
    right: .1em;
}
.deleteIcon:hover {
    cursor: pointer;
    color: #930000;
}
.listingConfigLabel .deleteIcon:hover {
    color: #fd5656;
}

.headerText {
    margin-left:5px;
}

.indicatorContainer{
    padding: 5px;
}

.buttonContainer{
    display: flex;
}

.indicatorSeparator{
    height:30px;
}

.checkboxIcon {
	width: 20px;
	min-width: 20px;
	max-height: 20px;
	height: 20px;
	min-height: 20px;
	max-height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 3px;
	transition: all .25s;
	cursor: pointer;
}

.listingConfigDataRowInput .checkbox {
    padding-top: 1.3em;
}

.checkbox .disabledSquare {
	background-color: transparent;
	border: 0px;
}

.checkbox .disabledSquare .checkboxIcon {
	display: block;
	color: var(--lightGrey);
	background-color: var(--lightGrey);
	border-radius: 3px;
}
.checkbox .activeCheckbox {
	border: 0px;
}
.checkbox .activeCheckbox .checkboxIcon {
	color: var(--white);
	background-color: var(--accent);
	border-radius: 3px;
}

.checkbox .activeCheckbox{
	background-color: transparent;
}

/* ======================================================= */
/* ==================== MEDIA QUERIES ==================== */
/* ======================================================= */
@media screen and (max-width: 1200px) {
    .listingConfigSelectLabel > div {
        max-height: 30px;
    }
}

.toggleContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 5px 0;
    width: 50%;
}

.concatField {
    display: flex;
}