.tagSearch {
	width: auto;
	min-width: 15vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 0 5px;
	border-bottom: 2px solid var(--lightGrey);
	border-radius: 6px 6px 0 0;
	cursor: text;
	transition: all .25s;
	/* overflow: scroll; */
}

.tagSearch svg {
	font-size: .8em;
	margin: 0 5px 0 0;
}
/* Table scroll container */
.tableTopScroller{
	overflow: scroll;
	width: 100%;
	margin: 1.5em 0 0 0;
}

.tableTopScrollerChild{
	height: 1px;
}

.tableScroll {
	position: relative;
	width: 100%;
	height: 100%;
	overflow-x: scroll;
	overflow-y: auto;
}

.table {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.thead {
	z-index: 6;
	/* position: -webkit-sticky; */
	position: sticky;
	/* width: 100%; */
	top: 0;
	height: fit-content;
}

.thead .tr {
	z-index: 6;
	position: relative;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
}

/* Maybe remove this eventually?  */
.thead .tr:nth-child(1) {
	display: none;
	width: 0;
}

.th {
	/* position: -webkit-sticky; */
  	position: sticky;
  	top: 0;
	/* width: 7.5vw; */
	width: var(--listingsContentGroupColWidth);
	height: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	color: var(--white);
	background-color: var(--black);
	font-size: .6em;
	font-weight: bold;
	border-left: 1px solid var(--white);
	border-right: 1px solid var(--white);
}
.th:first-child{
	border-left: 0px;
}
.th.checkbox {
	z-index: 5;
	/* position: -webkit-sticky; */
	/* position: sticky; */
	width: 50px;
	top: 0;
	left: 0;
	font-size: 1em;
}
.th.checkbox .thContainer, .th.scheduling .thContainer {
	justify-content: center;
	padding: 0;
}
.th.checkbox .iconContainer, .th.scheduling .iconContainer {
	display: none;
}
.th.checkbox .nameContainer, .th.scheduling .nameContainer {
	text-align: center;
}

.thContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 0 15px;
}

.thFilter {
	width: 100%;
}

.thFilter input {
	box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	font-weight: 700;
	line-height: 1.5em;
	color: var(--white);
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #bdbdbd;
	outline: 0;
	transition: border .3s;
}

.thFilter input:placeholder-shown {
	text-overflow: ellipsis;
  }

.filterRow {
	z-index: 5;
	position: -webkit-sticky;
	position: sticky;
	/* width: 100%; */
	top: 50px;
	height: fit-content;
}

.filterRow .tr {
	z-index: 5;
	position: relative;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.filterRow .th {
	background-color: var(--white);
}

/* Maybe remove this eventually?  */
.filterRow .tr:nth-child(1) {
	display: none;
	width: 0;
}

.filterRow .th input {
	color: var(--black);
	font-size: 1.2em;
}

.tbody {
	position: relative;
	z-index: 4;
	left: 0;
}

.tbody .tr {
	position: relative;
	/* width: 100%; */
	display: flex;
	flex-direction: row;
	min-height: 3em;
}

.tbody .tr:hover .td {
	background-color: var(--blueWhite);
	cursor: pointer;
}

.td {
	position: relative;
	/* width: 7.5vw; */
	width: var(--listingsContentGroupColWidth);
	background-color: var(--white);
	transition: all .25s;
}
.td .checkbox, .td .scheduling {
	z-index: 5;
	position: -webkit-sticky;
	/* position: sticky; */
	left: 0;
	/* width: 50px; */
	font-size: 1em;
	border-bottom: 1px solid var(--lightGrey);
}
.checkbox .disabledSquare, .scheduling .disabledSquare {
	background-color: transparent;
	border: 0px;
}
.checkboxIcon {
	width: 20px;
	min-width: 20px;
	max-height: 20px;
	height: 20px;
	min-height: 20px;
	max-height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 3px;
	transition: all .25s;
	cursor: pointer;
}
.checkbox .disabledSquare .checkboxIcon, .scheduling .disabledSquare .checkboxIcon {
	display: block;
	color: var(--lightGrey);
	background-color: var(--lightGrey);
	border-radius: 3px;
}
.checkbox .activeCheckbox, .scheduling .activeCheckbox {
	border: 0px;
}
.checkbox .activeCheckbox .checkboxIcon, .scheduling .activeCheckbox .checkboxIcon {
	color: var(--white);
	background-color: var(--accent);
	border-radius: 3px;
}

.checkbox .activeCheckbox, .scheduling .activeCheckbox {
	background-color: transparent;
}

:root {
	--listingsCol0: 50px;
	--listingsCol1: 32vw;
}
.th.checkbox, .td.checkbox, .th.scheduling, .td.scheduling {
	width: var(--listingsCol0);
}

/* Content group columns */
/* Disabled column cells */
.disabledSquare {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: .8em;
	background: #F5F5F5;
	border: solid 2px #f0f0f0;
	/* margin: 5px; */
	padding: 0 15px;
	border-radius: 6px;
}
.disabledSquare .checkboxIcon {
	display: none;
}

/* Active column cells */
.activeCheckbox {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: .8em;
	background: #ccf9cc;
	border: solid 2px #9ee09e;
	/* margin: 5px; */
	padding: 0 15px;
	border-radius: 6px;
}
.activeCheckbox .checkboxIcon {
	width: 20px;
	height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: var(--white);
	background-color: forestgreen;
	border-radius: 50%;
}

.tdContainer {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	padding: 5px;
}

.name svg:first-child {
	margin: 0 0 0 5px;
}

.name svg {
	margin: 0 0 0 -5px;
}







/* Filter */
.filter {
	z-index: 5;
	position: fixed;
	width: 100%;
	top: 15em;
	height: 1em;
	padding-right: 25em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

/* Filter input */
.filter input {
	box-sizing: border-box;
	width: 100%;
	margin-bottom: 1em;
	/* padding: 5px; */
	font-size: 1em;
	font-weight: 700;
	color: var(--darkGrey);
	line-height: 1.5em;
	border: none;
	border-bottom: 2px solid var(--lightGrey);
	outline: 0;
	transition: border .5s;
}

.rolloverHeader {
	width: 100%;
	color: var(--black);
	font-size: 1.5em;
}

.configInfo {
	/* position: relative; */
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.configInfoHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.nameContainer {
	width: 80%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	/* line-height: 1.5em; */
	/* autoprefixer: off */
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	animation-duration: 3s;
	animation-name: fontwsizes;
	animation-iteration-count: infinite;
}

.iconContainer {
	width: 15%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	right: 0;
}

.photoIcon {
	max-width: 100%;
}

.configInfo:hover .rolloverBox {
	display: block;
}

.configInfo svg {
}

.rolloverBox {
	display: none;
	z-index: 5;
	position: absolute;
	top: 55px;
	left: 0;
	width: 250px;
	padding: 15px;
	color: var(--black);
	background-color: var(--white);
	border-radius: 6px;
	word-break: break-all;
	border: 1px solid var(--black);
}

.rolloverContent {
	position: relative;
	width: auto;
	word-break: break-all;
}

.secondaryNav {
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: normal;
	padding: 0 30px 0 0;
	margin-top: 10px;
}

.secondaryNav > div{
	margin-right: 10px;
}