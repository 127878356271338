/* messagingOptions.css */

.messagingOptionsContainer {
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    margin-bottom: 20px ;
    border: 1px solid #ddd;
}

.sectionTitle {
    font-size: 18px;
    color: #333;
    margin-bottom: 15px;
    text-transform: uppercase;
    background-color: #606060;
    color: white;
    padding: 10px;
    border-radius: 4px;
    text-align: center;
}

.listingContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.fieldGroup {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.label {
    font-size: 14px;
    color: #555;
    font-weight: bold;
}

.select, .input {
    padding: 6px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    transition: border-color 0.3s ease;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
}

.select:focus, .input:focus {
    border-color: #007bff;
    outline: none;
}

.selectedSystemContainer {
    padding: 10px;
    background-color: #f1f1f1;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    margin-top: 10px;
}

.subHeading {
    font-size: 14px;
    color: #444;
    margin-bottom: 10px;
    text-align: center;
}

.systemInfoContainer {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #666;
    margin-bottom: 10px;
}

.systemInfo {
    font-size: 14px;
    color: #666;
    margin: 0;
}

.buttonContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.button {
    padding: 6px 12px;
    font-size: 14px;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    transition: background-color 0.3s ease;
}

.buttonGroup {
    display: flex;
    gap: 10px;
}

.deleteButton {
    background-color: #d9534f; /* Red color */
    border: none;
    color: white;
    cursor: pointer;
}

.deleteButton:hover {
    background-color: #c9302c; /* Darker red */
}

.button:hover {
    background-color: #0056b3;
}

.button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.5);
}



@media screen and (max-width: 600px) {
    .messagingOptionsContainer {
        padding: 10px;
    }

    .select, .input {
        font-size: 13px;
        padding: 5px;
        max-width: 100%;
    }

    .button {
        padding: 5px 10px;
        font-size: 13px;
    }
}
