.legend {
	position: absolute;
    top: 100px;
    right: 30px;
}

.note {
	color: red;
}

.secondaryNav {
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 30px 0 0;
	margin-top: 0;
}

.secondaryNavButtons {
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.nowShowing {
	width: auto;
	font-size: .8em;
	color: var(--darkGrey);
	text-align: right;
}

.table {
	margin: 1.5em 0 0 0;
}

.filterInstructions {
	width: 100%;
	font-size: 2em;
	color: var(--lightGrey);
	padding: 30px 15px;
	text-align: center;
}

.thead {
	
}

.thead .tr {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.th {
	height: 50px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	color: var(--white);
	background-color: var(--black);
	font-size: 1em;
	font-weight: bold;
	padding: 0 0 0 15px;
	text-align: left;
	border-left: 2px solid var(--white);
}

.systemsFilter {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	background-color: var(--white);
}

.systemsFilter .td {
	padding: .8em 2em .8em 1em;
}

.oneLine {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-left: .5em;
}

.projectNumber {
	font-size: 1.25em;
	font-weight: bold;
	color: var(--midGrey);
	padding: 0 .5em 0 0;
}

.displayName {
	font-size: 1.25em;
	font-weight: bold;
	color: var(--black);
	padding: 0 .5em 0 0;
}

.icons {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 0 .5em 0 0;
}

.icons svg {
	margin: 0 10px 0 0;
	transition: all .25s;
}

.icons div:hover svg {
	color: var(--accentDark);
}

.onlineExpires p {
	margin: 0;
	padding: 0;
}

.systemTitle {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.orientationIcon {
	width: 50px;
	height: 50px;
	margin: 0 10px 0 0;
}

.resolution {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.resolution svg {
	width: 1.25em;
	fill: var(--black);
	margin: 0 0 0 .25em;
	padding: 0;
}

.systemName {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.condorPreviewIcon, .condorMobileIcon {
    transform: scale(1.2);
    width: 2em;
}

.previewFullContainer {
	width: 100%;
	height: 100%;
	float: left;
	font-size: 1em;
	overflow: hidden;
	padding: 0 0 2em 0;
}

.previewIFrameFullContainer {
	width: 100%;
	height: 100%;
	float: left;
	overflow: auto;
	padding: 0 0 2em 0;
	position: relative;
}

.previewFull {
	max-width: inherit;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.previewIconContainer {
	width: 50px;
	height: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.previewIconContainer img {
	max-width: inherit;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}

.previewIcon {
	width: 100%;
	height: auto;
}

.previewButtons {
	position: absolute;
	width: 100%;
	height: 4em;
	bottom: 0;
	left: 0;
	display: flex;
	justify-content: flex-end;
	background-color: var(--white);
	padding: 1em 2em;
	border-top: 1px solid var(--black);
}
.previewButtons button {
	margin-left: 1em;
}

.animDropdown{
	display: flex;
    flex-direction: row;
}

.animDropdown > div{
	width: 25%;
}

.animDropdown > span{
	margin-right: 15px;
	margin-left: 60px; 
	line-height: 2.5em;
	color: #666;
}

.expirationContainer{
	display: flex;
	flex-direction: row;
}

.expirationContainer p{
	margin-right: 10px;
	padding: 10px;
}

.yellowwarning{
/* background: rgb(255 255 0 / 50%); */
border: 2px solid rgb(197 197 0);
border-radius: 5px;
padding: 10px;
}
.orangewarning{
/* background: rgb(255 125 0 / 50%); */
border: 2px solid #bd5a00;
border-radius: 5px;
padding: 10px;
}
.redwarning{
/* background: rgb(255 0 0 / 50%); */
border: 2px solid #c30000;
border-radius: 5px;
padding: 10px;
}

.topWarningContainer{
	width: auto;
	display: flex;
}

