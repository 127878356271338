.legend {
	position: absolute;
    top: 100px;
    right: 30px;
}

.note {
	color: red;
}

.secondaryNav {
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 30px 0 0;
}

.secondaryNavButtons {
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 1em;
}

.nowShowing {
	width: auto;
	font-size: .8em;
	color: var(--darkGrey);
	text-align: right;
}

.table {
}

.filterInstructions {
	width: 100%;
	font-size: 2em;
	color: var(--lightGrey);
	padding: 30px 15px;
	text-align: center;
}

.thead {
	
}

.th {
	height: 50px;
	color: var(--white);
	background-color: var(--black);
	font-size: 1em;
	font-weight: bold;
	padding: 0 0 0 15px;
	text-align: left;
}

.td {
	padding: 15px;
}

.container {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	padding: 15px 0 0 0;
	border-radius: 0 0 4px 4px;
}

.projectHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	background-color: var(--black);
	color: var(--white);
	margin: 0;
	padding: .75em 0;
	border-radius: 4px;
}

.projectHeader th {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 0 1em;
	border-left: 1px solid var(--white);
}

.projectHeader th:first-child {
	border-left: 0px;
}

.projectHeader h2 {
    width: 100%;
	color: var(--black);
    margin: 0;
	padding: 0;
}

.projectFilter {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	background-color: var(--white);
	padding: 15px 0;
}

.projectFilter td {
	padding: 0 20px;
}

.projects {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	background-color: var(--white);
	/* padding: 0 0 60px 0; */
	border-radius: 0 0 4px 4px;
}

.project {
	width: 100%;
	height: 4em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin: .4em 0;
	padding: 0;
	font-size: 1em;
	font-weight: 700;
	color: var(--black);
	background-color: var(--white);
	border: solid 1px #e8e8e8;
	border-radius: 4px;
	box-shadow: 0.1875em 0.1875em 0.375em rgb(0 0 0 / 6%);
	cursor: pointer;
	transition: all .35s;
}

.project:hover {
	/* transform: scale(1.005); */
	background-color: #EDF8FF;
	border: solid 1px #76C9FF;

}

.projectNumber {
	width: auto;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	/* font-size: 24px; */
	background-color: #CCEBFF;
	color: var(--black);
	padding: 0 1.5em;
	border-radius: 4px 0 0 4px;
}
.project:hover .projectNumber {
	background: #c2e7ff;
}
.name {
	width: auto;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	/* font-size: 24px; */
	color: var(--accent);
	padding: 0 1.5em;
}

.systemGroupNameColumn {
	/* font-size: 12px; */
	font-weight: 500;
	color: var(--black);
	padding: 0 1.5em;
}

.salesforce {
	/* font-size: 12px; */
	font-weight: 500;
	color: var(--black);
	padding: 0 1.5em;
}

.systems {
	/* font-size: 12px; */
	font-weight: 500;
	color: var(--black);
	padding: 0 1.5em;
}

.featuresWindow {
	position: absolute;
    top: 155px;
    left: 62.5px;
	width: 300px;
	height: 225px;
    padding: 1.5em;
	font-size: .7em;
	font-weight: 600;
	line-height: 1.4;
	color: var(--black);
    background: white;
    border: solid 1px black;
    border-radius: .5em;
    z-index: 20;
}
.featuresHeader {
	width: 100%;
	height: 30px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: .5em;
	font-size: 1.5em;
	font-weight: 700;
	color: var(--black);
}
.featuresHeader button {
	font-size: 1em;
	color: var(--black);
	background-color: transparent;
	margin: 0;
	padding: 0;
	border: 0;
	cursor: pointer;
	transition: all .25s;
}
.featuresHeader button:hover {
	color: var(--accent);
}

.featureElements {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	padding: 0 0 30px 0;
}

.featureElement {
    width: calc(50% - 30px);
	min-height: 1em;
	font-size: 1.25em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin: 5px;
}

.featureElement svg {
	margin: 0 5px 0 0;
}

/* Modal styles */

.modalContent {
	width: 100%;
	height: calc(100% - 2em);
	/* display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap; */
	padding: 1.5em 2em;
	overflow: auto;
}

.modalContent.normalizePadding {
	padding: 1.5em 2em;
}

.modalContent input {
	margin: 0 0 10px 0;
}

.sectionLabel {
	width: 100%;
	margin-bottom: .3125em;
	color: var(--darkBlue);
	font-weight: 700;
	font-size: 1.2em;
	text-transform: uppercase;
}

.label {
	width: 100%;
}

.modalButtons {
	position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 0 30px;
    border-top: 1px solid var(--black);
}

@media (min-width: 1100px) {
	.featuresWindow {
		top: 167.5px;
		left: 67.5px;
		width: 350px;
	}
}

@media (min-width: 1300px) {
	.featuresWindow {
		top: 180px;
		left: 72.5px;
		width: 400px;
		height: 250px;
	}
}

@media (min-width: 1500px) {
	.featuresWindow {
		top: 190px;
		left: 77.5px;
		height: 250px;
	}
}

@media (min-width: 1700px) {
	.featuresWindow {
		top: 205px;
		left: 82.5px;
	}
}