.pageHeader {
    position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	padding: 1.5em 0 0 0;
}

.listingContainer {
    display: flex;
    margin: 1.5em 0 3em 0;
}

.listingConfigContainer {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-right: 20px;
}

.listingConfigLabel {
    position: relative;
    background-color: #606060;
}

.listingConfigLabel h1 {
    position:relative;
    text-transform: uppercase;
    margin: 5px;
    padding: 0 5px;
    font-size: 1.25em;
    color: white;
}

.listingConfigLabel span{
    font-weight: 300;
}

.listingConfigCode{
    margin-top:2px;
    margin-left: 10px;
    font-weight: 300;
}

.listingConfigData {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}

.listingConfigDataRow {
    display: flex;
    position:relative;
    margin: 5px 0;
}

.listingConfigDataRowInput {
    width: 50%;
    margin: 0;
    padding: 0 10px;
}

.listingConfigDataRowInput * {
    padding: 0;
}

.listingConfigDataRowInput > div {
    font-size: .875em;
    font-weight: 600;
    color: var(--black);
    padding-bottom: 5px;
    padding-top: 5px;
    display: flex;
    flex-direction: column;
}

.listingConfigDataRowInput > div > input{
    height: 30px;
}

.listingConfigDataRowInput > div.listingConfigSubcontainer{
    display:flex;
    flex-direction: row;
}

.textInputContainer{
    max-width: 300px;
}

.text-small input{
    padding: 0.15em 1em 0.15em 1em !important;
    background-color: red !important;
}

.listingConfigSelectLabel {
    padding: 0;
    flex-grow: 1;
}

.listingConfigSelectLabel > div {
    min-height: 0;
    line-height: 1em;
    padding-left: 5px;
}


.button {
    width: fit-content;
    margin: 5px;
    display: flex;
    justify-content: center;
    margin-left:10px;
}

.deleteIcon {
    display: inline-block;
    align-self: center;
    color: #eee;
}

.listingConfigDataRow .deleteIcon{
    color: #ccc;
    margin-left: 5px;
}

.listingConfigLabel .deleteIcon {
    position: absolute;
    right: .1em;
}
.deleteIcon:hover {
    cursor: pointer;
    color: #930000;
}
.listingConfigLabel .deleteIcon:hover {
    color: #fd5656;
}

.headerText {
    margin-left:5px;
}

.headerDetails{
    margin-bottom: 20px;
}

.indicatorContainer{
    padding: 5px;
}

.buttonContainer{
    display: flex;
}

.indicatorSeparator{
    height:30px;
}


/* ======================================================= */
/* ==================== MEDIA QUERIES ==================== */
/* ======================================================= */
@media screen and (max-width: 1200px) {
    .listingConfigSelectLabel > div {
        max-height: 30px;
    }
}