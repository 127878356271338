/* Table scroll container */
.tableTopScroller{
	overflow: scroll;
	width: 100%;
	margin: 1.5em 0 0 0;
}

.tableTopScrollerChild{
	height: 1px;
}

.tableScroll {
	position: relative;
	width: 100%;
	height: 100%;
	overflow-x: scroll;
	overflow-y: auto;
}

.table {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.thead {
	z-index: 6;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
}

.thead .tr {
	z-index: 6;
	position: relative;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
}

/* Maybe remove this eventually?  */
.thead .tr:nth-child(1) {
	display: none;
	width: 0;
}

.th {
	position: -webkit-sticky;
  	position: sticky;
  	top: 0;
	width: var(--slideshowContentGroupColWidth);
	height: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	color: var(--white);
	background-color: var(--black);
	font-size: .6em;
	font-weight: bold;
	border-left: 1px solid var(--white);
	border-right: 1px solid var(--white);
}
.th:first-child{
	border-left: 0px;
}
.th.imgElm {
	z-index: 5;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	font-size: 1em;
}
.th.description {
	z-index: 5;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	font-size: 1em;
}
.th.schedule{
	z-index: 5;
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	font-size: 1em;
}


.thContainer {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 15px;
}

.thFilter input {
	box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	font-weight: 700;
	line-height: 1.5em;
	color: var(--white);
	background-color: transparent;
	border: none;
	border-bottom: 1px solid #bdbdbd;
	outline: 0;
	transition: border .3s;
}

.filterRow {
	z-index: 5;
	position: -webkit-sticky;
	position: sticky;
	top: 50px;
	height: fit-content;
}

.filterRow .tr {
	z-index: 5;
	position: relative;
	top: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.filterRow .th {
	background-color: var(--white);
}

/* Maybe remove this eventually?  */
.filterRow .tr:nth-child(1) {
	display: none;
	width: 0;
}

.filterRow .th input {
	color: var(--black);
	font-size: .8em;
}

.tbody {
	position: relative;
	z-index: 4;
}

.tbody .tr {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
}

.tbody .tr:hover .td {
	background-color: var(--blueWhite);
	cursor: pointer;
}

.td {
	position: relative;
	width: var(--slideshowContentGroupColWidth);
	background-color: var(--white);
}
.td.imgElm {
	z-index: 5;
	position: -webkit-sticky;
	position: sticky;
	height: 80px;
	float: left;
	font-size: .875em;
	overflow: hidden;
}
.td.imgElm img {
    max-width: inherit;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
.td.description {
	z-index: 5;
	position: -webkit-sticky;
	position: sticky;
	font-size: .875em;
	padding: .3em .8em;
	border-bottom: 1px solid var(--lightGrey);
}
.td.schedule {
	z-index: 5;
	position: -webkit-sticky;
	position: sticky;
	font-size: .875em;
	padding: .3em .8em;
	border-bottom: 1px solid var(--lightGrey);
}

/* Shared column styles */
:root {
	--sideNavWidth: 21vw; /*  used by JS */
    --slideshowTotalWidth: calc(100vw - var(--sideNavWidth)); /* total slideshow content width minus the side nav */
	
	/* SETTINGS */
}

.th.imgElm, .td.imgElm {
	width: var(--slideshowWidthCol0);
	left: 0;
}

.th.description, .td.description {
	width: var(--slideshowWidthCol1);
	left: var(--slideshowWidthCol0);
	overflow: hidden;
}

.th.resolution, .td.resolution {
	width: var(--slideshowWidthCol2);
}

.th.order, .td.order {
	width: var(--slideshowWidthCol3);
}

.th.startDate, .td.startDate {
	width: var(--slideshowWidthCol4);
}

.th.endDate, .td.endDate {
	width: var(--slideshowWidthCol5);
}

.th.startTime, .td.startTime {
	width: var(--slideshowWidthCol6);
}

.th.endTime, .td.endTime {
	width: var(--slideshowWidthCol7);
}

.th.daysCol, .td.daysCol {
	width: var(--slideshowWidthCol8);
}

/* Content group columns */
/* Disabled column cells */
.td .disabledSquare {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: .8em;
	background: #F5F5F5;
	border: solid 2px #f0f0f0;
	/* margin: 5px; */
	padding: 15px;
	border-radius: 6px;
}
.td .disabledSquare .checkboxIcon {
	display: none;
}

/* Active column cells */
.td .activeCheckbox {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: .8em;
	background: #ccf9cc;
	border: solid 2px #9ee09e;
	/* margin: 5px; */
	padding: 15px;
	border-radius: 6px;
}
.td .activeCheckbox .checkboxIcon {
	width: 20px;
	height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	color: var(--white);
	background-color: forestgreen;
	border-radius: 50%;
}

.tdContainer {
	width: 100%;
	height: 100%;
	padding: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
}

.days {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: 0 0 5px 0;
}


.day {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	width: 20px;
	min-width: 20px;
	max-width: 20px;
	height: 20px;
	min-height: 20px;
	max-height: 20px;
	margin: 0 1px;
	color: var(--white);
	font-size: .6em;
	font-weight: bold;
	background-color: #989898;
	border-radius: 50%;
}

.day:first-child {
	margin: 0 1px 0 0;
}

.times {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.dates {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.rolloverHeader {
	width: 100%;
	color: var(--black);
	font-size: 1.5em;
}

.rolloverContent {
}

.configInfo {
	/* position: relative; */
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.configInfoHeader {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
}

.nameContainer {
	width: 80%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	/* line-height: 1.5em; */
	/* autoprefixer: off */
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 2;
	overflow: hidden;
	animation-duration: 3s;
	animation-name: fontwsizes;
	animation-iteration-count: infinite;
}

.iconContainer {
	width: 15%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	right: 0;
}

.configInfo:hover .rolloverBox {
	display: block;
}

.configInfo svg {
}

.rolloverBox {
	display: none;
	z-index: 5;
	position: absolute;
	top: 55px;
	left: 0;
	width: 250px;
	padding: 15px;
	color: var(--black);
	background-color: var(--white);
	border-radius: 6px;
	word-break: break-all;
	border: 1px solid var(--black);
}

.rolloverContent {
	position: relative;
	width: auto;
	word-break: break-all;
}



/* Slideshow.js */
.viewWindowContent {
    width: 100%;
    display: flex;
	flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.viewWindowContent svg {
	font-size: 1.3em;
	cursor: pointer;
}
.viewWindowContent svg:hover {
	color: #009fda;
}
.dataColumn, .contentGroupsColumn {
    width: 50%;
}

.sectionHeader {
	display: flex;
	align-items: center;
}
.sectionHeaderText {
	margin: .5em .5em .4em 0;
	font-weight: 700;
}
.tagElement {
	display: flex;
	align-items: center;
	margin-bottom: .4em;
	font-size: .9em;
	font-weight: 500;
}
.colDirectionsCont {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 19em;
	margin-top: 1em;
	padding: .3em 0;
	font-size: .7em;
	color: #484848;
	background: #e4e4e4;
	border-radius: 1em;
}
.colDirectionsContError {
	color: white;
	background: red;
}
.checkbox {
	margin-right: .3em;
}
.checkbox div {
	display: flex;
}

.devRow {
	display: flex;
	align-items: center;
	padding-right: 2em;
}
.columnLabel {
	/* flex-grow: 1; */
}
.disabledCheckmark svg,
.disabledCheckmark:hover svg {
	color: gray;
	cursor: not-allowed;
}
.dataColumnWidth {
	display: flex;
	align-items: center;
	width: 4em;
}
.input {
	width: 3em;
	margin: 0 .3em;
	text-align: center;
	border: solid 1px gray;
	border-radius: 3px;
}
.inputLabel {
	width: 10em;
}
.contentGroupSettings {
	display: flex;
	align-items: center;
}
.iconShowHide {
	justify-content: center;
	display: flex;
	margin-left: .3em;
	width: 2em;
}
.contentGroupWidth {
	display: flex;
	align-items: center;
}
.saveSettingsButton {
	width: 100%;
	display: flex;
	justify-content: flex-start;
	padding-top: 1em;
}

.inputSmall {
	width: 3em;
	margin: 0 .3em;
	/* margin-right: .1em; */
	text-align: center;
	border: solid 1px gray;
	border-radius: 3px;
}