.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: auto;
    padding: 1.5em 0 0 0;
}

.headerBtnContainer {
    display: flex;
    margin: .5em 0 2.5em 0;
}

.headerBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 30px; */
    margin-right: .7em;
    padding: .3em .7em;
    border: 1px solid #5a5a5a;
    border-radius: .4em;
}

.headerBtnNum {
    margin-right: .2em;
    color:#0BAEEF;
    font-weight: 700;
}

.headerBtnActive, .headerBtnActive .headerBtnNum {
    background-color: #0BAEEF;
    color: white;
    border: none;
}

.headerBtn:hover {
    cursor: pointer
}

.buttonContainer {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    height: 150px;
}

.buttonContainer button {
    height: 50px;
}

.stepFiveContainer {
    display: flex;
}

.stepFiveSystems {
    width: 21vw;
    margin-right: 3em;
}
.systemsScroller {
    height: 70vh;
    overflow-y: auto;
}
.systemsContainerHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .25em 0;
	border-bottom: solid 1px black;
}

.headerLabel {
	font-size: 1.25em;
	font-weight: 700;
	color: #545454;
}

.systemGroupLabel {
    color: grey;
    margin: 1em 0;
}
.systemGroupText {
    margin-bottom: .3em;
}
.systemLabel {
	position: relative;
	display: flex;
    margin-right: .4em;
	padding: .7em .5em;
	font-weight: 600;
	font-size: .875em;
	color: #555555;
	border: solid 2px transparent;
	border-radius: .7em;
    width: 100%;
    justify-content: space-between;
}

.systemLabel:after {
	position: absolute;
	display: block;
	width: 97%;
	bottom: -3px;
	left: .7em;
	content: '';
	border-bottom: solid #e8e8e8 1px;
}

.systemLabel:hover {
	border-color: hsl(200deg 100% 85%);
	background: hsl(200deg 100% 98%);
	cursor: pointer;
}
.systemIcon {
    width: 1.7em;
    text-align: right;
    padding-right: .2em;
}

.activeSystemLabel {
    background-color: #F6FFF7;
    border: solid 2px #38AC4A;
}

.disabledSystemLabel {
    color: darkgrey;
    opacity: 0.2;
}

.disabledSystemLabel:hover {
    cursor: not-allowed;
}

.directoryNumber, .projectNumber {
    opacity: .5;
}

.systemLabelContainer {
    display: flex;
}

.condorIconsContainer {
    display: flex;
}

.condorPreviewIcon, .condorMobileIcon {
    transform: scale(1.2);
    width: 2em;
}