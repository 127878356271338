.secondaryNav {
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0 30px 0 0;
}

.secondaryNavButtons {
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.userSearch {
	width: auto;
	min-width: 15vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: 5px;
	border-bottom: 2px solid var(--lightGrey);
	border-radius: 6px 6px 0 0;
	cursor: text;
	transition: all .25s;
	/* overflow: scroll; */
}

.userSearch input {
    width: 100%;
    font-size: 1em;
    border: 0px;
    outline: 0;
    box-shadow: none;
}

.userSearch svg {
	font-size: .8em;
	margin: 0 5px 0 0;
}

.table {
	margin: 1.5em 0 0 0;
}

.thead {
    width: 100%;
}

.thead .tr {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.th {
	height: 50px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	color: var(--white);
	background-color: var(--black);
	font-size: 1em;
	font-weight: bold;
	padding: 0 0 0 15px;
	text-align: left;
	border-left: 2px solid var(--white);
}

.th:nth-child(1), .tbody .td:nth-child(1) {
    width: 10%;
}

.th:nth-child(2), .tbody .td:nth-child(2) {
    width: 5%;
}

.th:nth-child(3), .tbody .td:nth-child(3) {
    width: 25%;
}

.th:nth-child(4), .tbody .td:nth-child(4) {
    width: 45%;
}

.th:nth-child(5), .tbody .td:nth-child(5) {
    width: 15%;
}

.tbody {
	/* padding: 0 .5em; */
}

.tbody .tr {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: normal;
	justify-content: flex-start;
	transition: all .25s;
}

.tbody .tr:hover {
	background-color: var(--blueWhite);
	cursor: pointer;
}

.tbody .td {
	padding: 15px;
	border-bottom: 1px solid var(--lightGrey);
}

.tdContainer {
	width: 100%;
	height: 100%;
	padding: 5px;
}

.td .disabledSquare {
	background-color: transparent;
	border: 0px;
}
.td .checkboxIcon {
	width: 20px;
	min-width: 20px;
	max-height: 20px;
	height: 20px;
	min-height: 20px;
	max-height: 20px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding: 3px;
	transition: all .25s;
}
.td .disabledSquare .checkboxIcon {
	display: block;
	color: var(--lightGrey);
	background-color: var(--lightGrey);
	border-radius: 3px;
}
.td .activeCheckbox {
	border: 0px;
}
.td .activeCheckbox .checkboxIcon {
	color: var(--white);
	background-color: var(--darkGrey);
	border-radius: 3px;
}
.td .activeCheckbox {
	background-color: transparent;
}

/* Modal */

.userModal {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
    flex-direction: row;
    padding: 30px;
	overflow: auto;
}

.integrationInputsContainer {
	width: 100%;
}

.halfcolumn {
	width:50%;
	padding: 0 15px;
}

.halfcolumn:not(:last-of-type) {
	border-right: 1px solid #ccc;
}

.halfcolumn button{
	margin-top: 10px;
}

.column1, .column2 {
    /*width: 50%;*/
}

.field {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 0 0 1em 0;
}

.field .label {
    width: 100%;
}

.field input[type='text'] {
    width: 100%;
    margin: .5em 0 0 0;
}

.mfaToggles {
	justify-content: space-between;
}

.mfaToggles .activeToggle input {
	margin-bottom: 1.5em;
}

.mfaToggleContainer {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.mfaToggleContainer .activeToggle {
	justify-content: center;
}

.userType input[type='checkbox'] {
    width: auto;
}

.activeToggle {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
    margin: .5em 0 0 0;
    padding: 0;
	font-size: .875em;
	font-weight: 600;
	color: var(--black);
}

.activeToggle input {
	margin: 0 15px 0 0;
}

.onOff:after {
	content: "";
}

.select {
	margin: 5px 0 0 0;
	width:100%;
}

.select > div:nth-of-type(2) {
	position: relative;
	z-index: 1000;
}

.bottom {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 0 30px;
}

.bottom button {
    margin: 0 0 0 15px;
}

.deleteModal {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}

.deleteModal span {
	font-weight: bold;
	color: var(--accentDark);
}

.warningText {
	margin-top: 1rem;
	color: red;
}

.selectButton {
	color: #00baff;
	text-decoration: underline;
	cursor: pointer;
}

.contentList{
	list-style-type: none;
	border:1px solid #ccc;
	padding:0 15px;
	max-height: 65%;
	overflow: auto;
	margin-top:5px;
}

.contentItem{
	width:100%;
	border-bottom:1px solid #ccc;
	padding: 10px 10px 10px 5px;
}

.contentItem:last-of-type{
	border-bottom: none;
}

.nameContainer > *, .contentBucket{
	display:inline-block;
}

.contentBucket{
	margin-left:10px;
}

.column3, .halfcolumn{
	height:100%;
}

.topContainer{
	position:relative;
}

.topContainer div{
	display:inline-block;
}

.topContainer .selectButton{
	float:right;
	margin-left:10px;
}

.contentFilter{
	padding: 5px;
    width: 100%;
    border: 1px solid #ccc;
    line-height: 1.75em;
    padding-left: 15px;
	margin-top:10px;
}

.propertyIconContainer{
	margin: 0 5px 0 10px;
    font-size: .75em;
    vertical-align: middle;
	height:100%;
	display:inline-block;
}

.propertyIconContainer:first-of-type{
	margin-left: 0;
}

.internalUser{
	color: red;
}

.marginSmall{
	margin-bottom: 10px;
}

.hasCredentialsWarning {
	padding-bottom: 1em;
	color: firebrick; 
}