.table {
	margin: 0 5px;
	border-collapse: collapse;
}

/* Maybe remove this eventually? */
.thead .tr:nth-child(1) {
	display: none;
	width: 0;
}

.tbody {
	background-color: transparent;
}

.tbody .tr {
	width: 100%;
	min-height: 100px;
	max-height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	background-color: var(--white);
	margin: 0 0 15px 0;
	padding: 0;
	border: 1px solid gray;
	border-radius: 6px;
	transition: all .2s;
	cursor: pointer;
}
.tbody .tr:hover {
	background: #EDF8FF;
	border: 1px solid var(--accent);
}

.td {
	background-color: transparent;
	padding: 15px;
}

.td:nth-child(1) {
	width: 35%;
	height: 100px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	font-size: 1.25em;
	font-weight: bold;
	color: var(--black);
	clear: both;
    overflow: hidden;
    white-space: nowrap;
	text-overflow: ellipsis;
	border-radius: 6px 0 0 6px;
	transition: all .5s;
}
.td:nth-child(2) {
	width: 15%;
	font-size: 1.25em;
	font-weight: bold;
	color: var(--lightGrey);
	clear: both;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
	text-overflow: ellipsis;
}
.td:nth-child(3) {
	width: 40%;
	max-height: 5.5em;
	font-size: 1em;
	font-weight: 600;
	line-height: 1.5em;
	color: var(--black);
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.td:nth-child(4) {
	width: 10%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 1.25em;
	font-weight: 500;
	color: var(--black);
	clear: both;
    overflow: hidden;
    white-space: nowrap;
	text-overflow: ellipsis;
}