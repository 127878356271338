.supportContainer {
    width: auto;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 4em 1em 3em 5em;
    overflow: auto;
}

.supportTitle {
    padding-top: 1.5em;
    color: #176599
}

.supportRequired {
    color: #A72D3B
}

.supportForm {
    display: flex;
    flex-direction: column;
    width: 800px;
}

.supportForm label {
    display: flex;
    width: 100%;
    margin-bottom: .6rem;
}

.supportFormLabel {
    width: 40%;
    padding: .6rem;
    background-color: #DFDFDF;
    text-align: right;
}

.supportFormLabel span {
    color: #A72D3B;
}

.supportForm label input, .supportForm label textarea, .supportForm label select {
    width: 60%;
    margin-left: .6rem;
    padding: .6rem;
    border: 1px solid #DDDDDD;
}

.customSelect {
    width: 60%;
    margin-left: .6rem;
}

.customSelect > div {
    border: 1px solid #DDDDDD;
    border-radius: 0;
}

.supportSubmitBtn {
    align-self: flex-end;
    background-color: #176599;
    border: none;
    padding: .4rem .8rem;
    color: white;
    cursor: pointer;
}

.supportSubmitBtn:hover {
    background-color: #0e4163;
}

.successMessage {
    color: green;
    display: none;
}

.errorMessage {
    color: #A72D3B;
    display: none;
}