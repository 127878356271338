
.infoBox {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    /* margin: 10px 0; */
    margin-bottom: 2em;
}

.infoBox:first-child {
    margin: 0 0 10px 0
}

.content {
    width: 95%;
    border-radius: 4px;
    background-color: var(--white);
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.formHeader {
    width: 100%;
    background-color: var(--black);
    margin: 0;
    padding: 10px 20px;
    border-radius: 4px 4px 0 0;
}

.formHeader h2 {
    width: 100%;
    color: var(--white);
    margin: 0;
    padding: 0;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px 20px 30px 20px;
}

.infoBoxUploader {
    width: 100%;
    height: auto;
    order: 100;
}

.inputsContainer{
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 0;
}

.dynamicInput{
    width: 80%;
    display: flex;
}
.text-small input[type="text"]{
    width: 80%;
}

.smallButton{
    vertical-align: middle;
    margin-left: 10px;
}

.smallButton svg{
    margin: auto !important;
}

.inputRow{
    display: inline;
    margin-bottom: 20px;
}

.smallInput{
    width:70% !important;
}

.rowContainer{
    width: 100%;
    height: auto;
    display: block;
    margin: 1.5em 0 0em 0;
    padding: 1.5em 0;
    overflow-y: auto;
}

@media (min-width: 850px) {
    .infoBox {
        width: 45em;
    }
}
@media (min-width: 1280px) {
    .infoBox {
        width: 32em;
    }
}
@media (min-width: 1700px) {
    .infoBox {
        width: 34em;
    }
}