.systemsModalContainer {
    width: 100%;
    height: calc(100% - 2em);
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: transparent;
    padding: 0 0 2em 0;
    overflow: auto;
}

.sectionLabel {
	width: 100%;
	margin-bottom: .3125em;
	color: var(--darkBlue);
	font-weight: 700;
	font-size: 1.2em;
	text-transform: uppercase;
}

.detailsColumn {
    width: 50%;
    height: auto;
    padding: 30px 30px 50px 30px;
}

.meshColumn {
    width: 50%;
    height: auto;
    padding: 30px 30px 50px 30px;
}

.detail {
}

.select > div:nth-of-type(1) > div:nth-of-type(1) > div:nth-of-type(2) > div:nth-of-type(2) {
	position: relative;
	z-index: 1000;
}

/* .select {
	z-index: 1000;
	position: absolute;
	top: 0;
    width: 100%;
	margin: 5px 0 0 0;
}

.select > div:nth-of-type(2) {
	position: relative;
	z-index: 1000;
} */

.systemsColumn {
    flex: 1;
    height: auto;
    padding: 30px 30px 50px 30px;
}

.system {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
}

.system .systemField {
    width: 100%;
}

.system input:disabled {
    border: 1px solid #d0d0d0;
    cursor: not-allowed;
}

.system input:disabled:hover {
    border: 1px solid #d0d0d0;
    cursor: not-allowed;
}

.delete {
    width: 4%;
    margin: 1.75em 5px 0 5px;
}

.delete svg {
    color: var(--darkGrey);
    transition: all .5s;
    cursor: pointer;
}

.delete svg:hover {
    color: var(--accent);
}

.buttons {
    position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 0 30px;
    border-top: 1px solid var(--black);
}

.buttons button {
    margin: 0 0 0 5px;
}

.small {
    background-color: green;
}

.fakeInputLabel {
    font-size: .875em;
    font-weight: 600;
    color: var(--black);
}

.fakeInput {
    box-sizing: border-box;
	width: 100%;
	font-size: 1em;
	font-weight: 400;
	line-height: 1.5em;
	color: black;
	padding: .25em 1em .25em 1em;
	border-radius: 4px;
	border: 1px solid #d0d0d0;
    background-color: #d0d0d01a;
	outline: 0;
	transition: border .3s;
    margin-top: 0px;
}