
.topHeader {
	padding: 1.5em 0 1.5em 0;
}
.description {
	font-size: .8em;
	color: var(--lightGray);
}
.content {
	position: fixed;
	z-index: 98;
	top: 0;
	margin-left: 3em;
	/* margin-left: 2.7vw; */
	/* width: 20em; */
	width: 17.3vw;
	height: 100vh;
	background-color: #F0F2F5;
	color: var(--darkGray);
	/* box-shadow: 0px 3px 15px rgba(0,0,0,0.2); */
	transition: all .1s;
	padding: 4em 1.5em 1.5em 1.5em;
	overflow: auto;
}
.contentText {
	position: relative;
}

.list {
	list-style: none;
	width: 100%;
	height: auto;
	margin: 0;
	padding: 0;
}
.list.hide {
	max-height: 0;
	overflow: hidden;
	transition: max-height .5s;
}
.list.show {
	overflow: hidden;
	transition: max-height 1s;
}

.header {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	font-size: 1em;
	font-weight: bold;
	padding: .5em 0;
	cursor: pointer;
}
.header svg {
	transform: rotate(0deg);
	transition: transform .3s;
}
.header:hover svg path {
	fill: var(--accent);
}
.show.header svg {
	transform: rotate(90deg);
}

.active {
	position: relative;
	color: var(--white);
	background-color: var(--black);
}
.active:after {
    position: absolute;
    top: .1em;
    right: -1.2em;
    width: 0;
    height: 0;
    content: '';
    border-top: 1.1em solid transparent;
    border-bottom: 1.1em solid transparent;
    display: block;
    z-index: 999;
    border-left: 1em solid var(--midGrey);
}


.component {
	position: relative;
	margin: 0;
	width: 100%;
	padding: .6em .5em .6em 1.5em;
	font-size: .8em;
	font-weight: 600;
	cursor: pointer;
	transition: all .3s;
}
.component svg {
	transform: rotate(45deg);
}
.component:hover {
	color: var(--white);
	background-color: #8c8c8c;
}
.active:hover {
	background-color: var(--black);
}
.componentName {
	padding: .75em .8em;
	cursor: pointer;
	transition: all .3s;
}
.componentName:hover {
	color: white;
    background: #525252;
}
.componentNameActive {
	padding: .75em .8em;
	cursor: pointer;
	transition: all .3s;
	color: white;
    background: #525252;
}
.componentLine {
	/* margin: .3em 0; */
	border-bottom: solid 1px var(--darkGray);
	margin: .3em 0;
	/* border-color: var(--darkGray); */
}

.systemGroupsTable {
	display: flex;
	/* margin-top: 10em; */
}

@media (max-width: 1700px) {
	.content {
		font-size: 15px;
	}
}
@media (max-width: 1500px) {
	.content {
		font-size: 14px;
	}
}
@media (max-width: 1300px) {
	.content {
		font-size: 13px;
	}
}
@media (max-width: 1100px) {
	.content {
		font-size: 12px;
	}
}
