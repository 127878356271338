.infoBox {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	border-radius: 4px;
	margin: 10px 0;
}

.infoBox:first-child {
	margin: 0 0 10px 0
}

.componentSelector {
	width: 320px;
	width: 20em;
	height: calc(100vh - 64px);
	padding: 2em 1.5em 1.5em 1.5em;
	color: var(--darkGray);
	background: #F0F2F5;
}

.formHeader {
	width: 100%;
	background-color: var(--black);
	margin: 0;
	padding: 15px 30px;
	border-radius: 4px 4px 0 0;
}

.formHeader h2 {
	width: 100%;
	color: var(--white);
	margin: 0;
	padding: 0;
}
.sectionLabel {
	margin-bottom: .3125em;
	color: var(--darkBlue);
	font-weight: 700;
	font-size: 1.2em;
	text-transform: uppercase;
}
.form {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 15px 30px;
}

.infoBoxUploader {
	width: 100%;
	height: auto;
	order: 100;
}

.slideshowModal {
	width: 100%;
	height: calc(100% - 2em);
	/* height: 75%; */
	display: flex;
	flex-direction: column;
	/* align-items: flex-start; */
	/* justify-content: center; */
	/* flex-wrap: wrap; */
	padding: 15px 0 0 0;
	overflow: auto;
}

.slideshowModalContainer {
	/* width: 100%; */
	/* height: 100%; */
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 1em 0 2em 0;
}
.libraryModalContainer {
	/* width: 100%; */
	/* height: 100%; */
	
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/* justify-content: space-between; */
	/* flex-wrap: wrap; */
	padding: 0;
	/* overflow: auto; */
	overflow-y: scroll;
}

.slideshowModal h2 {
	width: 100%;
	color: var(--darkBlue);
}

.top {
	width: 100%;
	font-size: 1em;
	color: var(--darkGrey);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 1.5em;
}

.top span {
	text-decoration: underline;
	cursor: pointer;
	transition: all .5s;
}

.top span:hover {
	color: var(--black);
}
.instructions {
	width: 100%;
	font-size: .8em;
	color: var(--darkGrey);
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 0 5.7em 1em 5.7em;
}
.label {
	width: 100%;
}

.column1 {
	width: 95%;
	margin: 0 auto;
	padding: 0 0 15px 0;
}

.uploader {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 0;
}

.uploaderContainer {
	position: relative;
	width: 100%;
	height: 19em;
	float: left;
	background-color: var(--lightGrey);
	padding: 15px;
	border: 1px solid var(--darkGrey);
	border-radius: 4px;
	overflow: hidden;
}

.uploaderContainer img {
	max-width: inherit;
	width: 100%;
	height: 100%;
	object-fit: scale-down;
}

.uploaderFileName {
	width: 30%;
	height: 100%;
	font-size: 10px;
	font-weight: bold;
	color: var(--darkGrey);
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	padding: 10px 0 0 0;
}

.uploaderButtons {
	width: 68%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding: 10px 0 0 0;
}

.uploaderButtons button {
	margin: 0 0 0 10px;
}

.description {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.order {
	width: 100%;
	display: flex;
	flex-wrap: wrap;
}

.column2 {
	width: 95%;
	margin: 0 auto;
	padding: 15px 0;
}

.contentControls {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin-bottom: .4em;
	color: var(--darkGrey);
}

.contentControls span {
	font-weight: 700;
	color: var(--black);
}

.contentControls svg {
	color: var(--lightGrey);
	transition: all .3s;
}

.contentFilter {
	width: 100%;
	font-size: 1em;
	margin: 0 0 15px 0;
	padding: 5px;
	border: 1px solid var(--lightGrey);
	border-radius: 5px;
}

.contentAssign {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
	width: 100%;
	height: auto;
	max-height: 250px;
	margin: 0;
	padding: 0 10px 10px 10px;
	background-color: #f7f7f7;
	overflow: auto;
}

.contentAssign li {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	font-size: 1.5em;
}

.contentBucket {
	width: 100%;
	padding: .7em 0;
	font-size: .6em;
	border-bottom: 1px solid rgb(216 216 216);
}

.contentAssign li svg {
	margin: 0 10px 0 0;
}
.pagination {
	display: inline-block;
	margin-left: .6em;
	padding: .2em .5em;
	border: solid 1px var(--lightGrey);
	border-radius: .3em;
	cursor: pointer;
	transition: all .3s;
}
.pagination:hover {
	border: solid 1px var(--accent);
}
.pagination:hover svg {
	color: var(--accent);
}


.scheduling {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex-wrap: wrap;
}

.contentToggle {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
    margin: 0 0 .5em 0;
    padding: 0 0 .5em 0;
	font-size: .875em;
	font-weight: 600;
	color: var(--black);
    border-bottom: solid #b9b9b9 1px;
}

.contentToggle input {
	margin: 0 15px 0 0;
}
.noLine {
	border: none;
}
.available {
	width: 100%;
	display: flex;
}

.notAvailable {
	width: 100%;
	display: none;
}

.notAvailable input {
	pointer-events: none;
}

.datePicker {
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: .5em 0 .5em 13%;
	overflow: hidden;
	display: flex;
	transition: all .3s ease-in-out;
	height: 70px;
}

.startDate, .endDate {
	width: 49%;
}

.timePicker {
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: .5em 0 .5em 13%;
	overflow: hidden;
	display: flex;
	transition: all .3s ease-in-out;
	height: 70px;
}

.timeDate, .timeDate {
	width: 49%;
}

.dayPicker {
	width: 100%;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: .5em 0 .5em 13%;
	overflow: hidden;
	display: flex;
	transition: all .3s ease-in-out;
	height: 70px;
}
.hide {
	/* display: none; */
	height: 0em;
	padding: 0 0 0 13%;
}
.show {
	/* display: flex; */
}
.bottom {
	position: absolute;
    z-index: 100;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    background-color: var(--white);
    padding: 0 30px;
    border-top: 1px solid var(--black);
}

.bottom button {
	margin: 0 0 0 15px;
}

.clearButton, .multiselectButton {
	color: var(--darkBlue);
	background-color: transparent;
	font-size: .8em;
	font-weight: bold;
	border: 0px;
	outline: 0;
	box-shadow: none;
	text-decoration: underline;
	cursor: pointer;
	transition: all .5s;
}

.multiselectButton {
	margin: 1em 1em 3em 0;
	padding: 0;
}

.clearButton:hover {
	color: var(--darkBlueHover);
}

.viewWindow {
    display: flex;
    flex-direction: column;
    left: .25em;
}
.viewWindowContent {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.dataColumn, .contentGroupsColumn {
    width: 50%;
}
.viewWindow h5 {
    font-size: 1.25em;
    margin: 0;
    padding: 10px 0;
}
.tagElement {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-size: 1.25em;
    padding: 0 0 5px 0;
}
.tagElement svg {
    margin: 0 5px 0 0;
}

@media (min-width: 1280px) {
	.column1 {
		width: 48%;
		height: 90%;
		padding: 0 0 30px 4em;
	}

	.column2 {
		width: 48%;
		padding: 0 4em 30px 0;
	}
}

@media (min-width: 1400px) {
	.infoBox {
		width: 50%;
		margin: 0;
	}
}

@media (min-width: 1600px) {
	.infoBox {
		width: 33.3%;
	}
}