:root {
	--col0Width: 0;
    --col1Width: 9.5em;
    --col2Width: 11.5em;
    --col3Width: 10em;
    --col4Width: 9em;
    --col5Width: 12em;
    --col6Width: 7em;

	--systemBorderColor: gray;
}

.table {
	overflow-y: auto;
	height: 100%;
	width: 100%;
}

.systemGroup {	
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	background-color: var(--white);
	margin-left: 1em;
	padding: 1em 1em 1em 0;
	border-top: 1px solid var(--lightGrey);
	cursor: pointer;
}

.systemGroup:first-child {
	margin-top: 0;
	border-top: 0;
}

.systemGroup .tr {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
}

.expander {
	width: 20px;
	height: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.5em;
}

.noExpander {
	width: 20px;
	height: 30px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: 1.5em;
}

.system {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding-top: .3em;
	padding-bottom: .3em;
	padding-left: 2.7em;
	cursor: pointer;
}

.system .tr {
	width: 100%;
	min-height: 4em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	flex-wrap: wrap;
	background-color: #EDF8FF;
	padding: 5px 0;
	border: 1px solid var(--accent);
	border-radius: 6px;
	transition: background-color .3s;
}
.system .tr:hover {
	background-color: #d5efff;
}

.tier0 {
    font-weight: bold;
    color: var(--black);
}

.system .td:nth-child(1) {
	display: none;
}

.system .td:nth-child(2) {
	flex-grow: 1;
	width: var(--col1Width);
	padding: 0 15px;
}
.system .td:nth-child(3) {
	width: var(--col2Width);
	padding: 0 15px;
}
.system .td:nth-child(4) {
	width: var(--col3Width);
	padding: 0 15px;
}
.system .td:nth-child(5) {
	width: var(--col4Width);
	padding: 0 15px;
}
.system .td:nth-child(6) {
	width: var(--col5Width);
	padding: 0 15px;
}
.system .td:nth-child(7) {
	width: var(--col6Width);
	padding: 0 15px;
}
.tier1 .system .td:nth-child(4) {
	width: calc(var(--col3Width) - .75em);
}
.tier2 .system .td:nth-child(4) {
	width: calc(var(--col3Width) - 1.5em);
}
.tier3 .system .td:nth-child(4) {
	width: calc(var(--col3Width) - 2.25em);
}

.test {background-color: rgb(0, 0, 0);}


.tier0 {
	position: relative;
	padding-right: .75em;
}
.tier1 {
	position: relative;
	margin-left: 2.8em;
	padding-right: .75em;
}
.tier2 {
	position: relative;
	margin-left: 2.8em;
	padding-right: .75em;
}



.tbody .firstChild {
	margin-bottom: 0;
}

.tier1Spacer {
	/* background: rgba(60, 255, 60, 0.329); */
	width: 100%;
	height: .6em;
}
.tier2Spacer {
	/* background: rgb(128 0 0 / 6%); */
	width: 100%;
	height: .6em;
}
.open {
	outline: solid 3px red;
}
.closed {
	outline: solid 3px green;
}

.secondaryNav {
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: normal;
	padding: 0 30px 0 0;
	margin-top: 0;
}

.secondaryNav > div{
	margin-left: 10px;
}
/* internal use for debugging */
.data {
	position: absolute;
	top: 1.5em;
	left: 35%;
	padding: .25em;
	z-index: 10;
	background: #8080805c;
	display: none; /* comment this out to see data */
}
.systemWithoutHover {
	cursor: default !important;
}
.systemWithoutHover svg {
	cursor: pointer;
}

.selectedSystemGroupProjectNumber {
	color: var(--accent);
}

.selectedSystemGroupDisplayName {
	color: var(--darkBlue);
}