.header {
	position: fixed;
	top: 0;
	z-index: 200;
	width: 100%;
	/* height: auto; */
	height: 4em;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	margin: 0;
	/* padding: 12px 15px 12px 23px; */
	padding: .8em 1em .8em 1.5em;
	background-color: var(--darkBlack);
	box-shadow: 0px 3px 6px 0 rgb(0 0 0 / 0.4);
}

.popoverBackground.hierarchyDropdownVisible,
.popoverBackground.userDropwdownVisible,
.popoverBackground.toolsVisible,
.popoverBackground.accountDropdownVisible {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.note {
	font-size: 10px;
	color: firebrick;
}

.pullDownName {
	padding: 0 .3em 0 .6em;
}
.pullDownAccountName {
	padding: 0 .3em 0 0;
}

.vertLine {
	width: 1px;
	height: 2em;
	margin-right: 1.25em;
	background: white;
}

.logo {
	/* width: 250px; */
	height: auto;
	/* padding: 0 15px 0 0; */
	padding: 0 1em 0 0;
}

.logo img {
	/* width: 155px; */
	width: 10em;
}

.search {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: justify-content;
	/* padding: 15px; */
	padding: 1em;
}

.search svg {
	color: var(--darkGrey);
	/* margin: 0 10px 0 0; */
	margin: 0 .625em 0 0;
}

.search input {
	box-sizing: border-box;
	width: 100%;
    font-size: 1em;
    line-height: 1.5em;
    color: var(--black);
    /* padding: 5px; */
    padding: .3125em;
    border: none;
    border-bottom: 2px solid var(--darkGrey);
    outline: 0;
    transition: border .5s;
}

.systemGroupNumber {
	color: var(--accent);
	padding: 0 5px 0 0;
}

.buttonNoAccounts {
	left: 14em
}

.hierarchy {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	color: var(--white);
}
.hierarchyDropdown {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	/* padding: 0 15px; */
	/* padding: 0 1.3em; */
	/* border-left: 2px solid var(--white); */
}
.hierarchyMenu {
	position: absolute;
	width: 22em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	top: calc(4em - 2px);
	left: 12.5em;
	background-color: var(--white);
	color: var(--black);
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
	border: solid 2px black;
	border-radius: 0 0 6px 6px;
	transition: all .5s;
	z-index: 100;
	max-height: 90vh;
	overflow: hidden;
}

.hierarchyMenu.hierarchyDropdownVisible {
	/* top: 80px; */
	top: calc(4em - 2px);
}

.hierarchyMenu.hierarchyDropdownHidden {
	top: -130vh;
}

.hierarchyMenu ul {
	list-style: none;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	margin: 0;
	padding: 0;
}

.hierarchyMenu .button {
	border-radius: 0;
}

.account {
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	color: var(--white);
}
.accountDropdown {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	/* padding: 0 15px; */
	/* padding: 0 1.3em; */
	/* border-left: 2px solid var(--white); */
}

.user {
	width: 35em;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	color: var(--white);
}

.userSupport {
	color: var(--white);
	text-decoration: none;
	margin-right: 1.3em;
}

.userDropdown {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	/* padding: 0 30px; */
	padding: 0 2em;
	cursor: pointer;
}

.userMenu {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	/* top: 80px; */
	top: calc(4em - 2px);
	/* right: 10px; */
	right: 3em;
	/* width: 300px; */
	width: 19em;
	background-color: var(--white);
	color: var(--black);
	border-radius: 0 0 6px 6px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
	border: solid 2px black;
	transition: all .5s;
	z-index: 100;
}

.userMenu.userDropwdownVisible {
	/* top: 80px; */
	top: calc(4em - 2px);
}

.userMenu.userDropdownHidden {
	top: -50vh;
}

.userMenu ul {
	list-style: none;
	width: 100%;
	margin: 0;
	padding: 0;
}

.userList li {
	width: 100%;
	color: var(--black);
	font-weight: bold;
}

.userList li a svg {
	/* font-size: 40px; */
	font-size: 2.5em;
	padding: 10px;
	/* padding: .625em; */
}

.userList li a {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	color: var(--black);
	/* padding: 15px; */
	padding: .3em 1em;
	text-decoration: none;
	transition: all .5s;
}

.userList li a:hover {
	background-color: var(--blueWhite);
	cursor: pointer;
}

.userAccountContainer {
	display: flex;
}

/* Universal */

.button {
	width: 100%;
	font-size: 1em;
	font-weight: bold;
	text-transform: uppercase;
	background-color: var(--accent);
	color: var(--white);
	padding: 1em 0;
	margin: 0;
	border: 0;
	border-radius: 0 0 4px 4px;
	box-shadow: none;
	cursor: pointer;
	outline: none;
	transition: background .5s;
}

.button:hover {
	background-color: var(--accentDark);
	cursor: pointer;
}

.link {
	display: flex;
	cursor: pointer;
	transition: color .2s;
}
.link:hover {
	color: #00b8ff;
}
.link svg {
	margin-top: .1em;
	transition: transform .3s;
}
.link.accountDropdownHidden svg:last-child,
.link.hierarchyDropdownHidden svg:last-child,
.link.userDropdownHidden [data-icon="caret-down"] {
	transform: rotate(90deg);
} 
.link.accountDropdownVisible svg:last-child,
.link.hierarchyDropdownVisible svg:last-child,
.link.userDropwdownVisible [data-icon="caret-down"] {
	transform: rotate(0deg);
}

.toolsTextBox {
	color: white;
    margin-right: 1.3em;
    padding: 0 .3em;
	font-weight: 700;;
    border: solid 2px #05adee;
    border-radius: .4em;
}
.headerLink .toolsTextBox:hover {
    border: solid 2px #ffffff;
}
.tools {
	width: 50%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	color: var(--white);
}

.toolsDropdown {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-end;
	padding: 0 2em;
	cursor: pointer;
}

.toolsMenu {
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	top: calc(4em - 2px);
	right: 11.8em;
	width: 13em;
	background-color: var(--white);
	color: var(--black);
	border-radius: 0 0 6px 6px;
	box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
	border: solid 2px black;
	transition: all .3s;
	z-index: 100;
}

.toolsMenu.toolsVisible {
	top: calc(4em - 2px);
}

.toolsMenu.toolsHidden {
	top: -50vh;
}

.toolsList {
	list-style: none;
	width: 100%;
	margin: 0;
	padding: 0;
}

.toolsList li {
	width: 100%;
	color: var(--black);
	font-weight: bold;
}

.toolsList li a svg {
    position: absolute;
    right: 10.6em;
    padding: 0;
    font-size: 1em;
}

.toolsList li a {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	padding: .5em 1em .5em 2.5em;
	color: var(--black);
	text-decoration: none;
	transition: all .5s;
}

.toolsList li a:hover {
	background-color: var(--blueWhite);
	cursor: pointer;
	border-radius: 0 0 6px 6px;
}

.oneLine {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	margin-left: .5em;
}

.projectNumber {
    font-weight: bold;
    color: var(--midGrey);
    padding: 0 .5em 0 0;
}

@media (max-width: 1700px) {
	.header,
	.hierarchyMenu,
	.userMenu {
		font-size: 15px;
	}
}
@media (max-width: 1500px) {
	.header,
	.hierarchyMenu,
	.userMenu {
		font-size: 14px;
	}
}
@media (max-width: 1300px) {
	.header,
	.hierarchyMenu,
	.userMenu {
		font-size: 13px;
	}
}
@media (max-width: 1100px) {
	.header,
	.hierarchyMenu,
	.userMenu {
		font-size: 12px;
	}
}